import gsap from 'gsap';
import PastCard from './PastCard';

export default class PastCardLoading extends PastCard {
    constructor(view, idx, eventDispatcher) {
        super(view, idx, eventDispatcher)
        this.view = view;
        this.idx = idx;
        this.eventDispatcher = eventDispatcher;
    
        this.background = this.view.querySelector('.asset');
        this.logo = this.view.querySelector('.logo-svg');
        this.txt = this.view.querySelector('.txt');
    }

    //////////////////////////////////////////////////////////////
    /////////////////////////// Card State ///////////////////////
    //////////////////////////////////////////////////////////////
    cardOn(delay, callback) {
        gsap.set(this.view, {
            display: 'block',
            pointerEvents: 'auto',
            onComplete: () => {
                gsap.delayedCall(delay, () => {
                    this.appearBackground(callback)
                })
                
            }
        })
    }
    cardOff(val, callback) {
        gsap.killTweensOf(this.view, 'display')
        gsap.set(this.view, { pointerEvents: 'none' })

        if (val == 0) {
            this.resetCard()
            gsap.set(this.background, { autoAlpha: 0 })
        } else {
            this.disappearText(callback)
        }
    }
    appearBackground(callback) {
        gsap.to(this.background, {
            autoAlpha: 1,
            duration: 0.6,
            ease: 'power1.out',
            onComplete: () => {
                if (this.background.tagName == 'VIDEO') {
                    if(!document.body.classList.contains('hide-video')) {
                        if (this.background.paused) {
                            this.background.play()
                        }
                    }
                }
                this.appearText(callback)
            },
            onStart: () => {
           
            }
        })
    }
    appearText(callback) {
        // array
        const _stagger = 0;

        gsap.to(this.logo, {
            autoAlpha: 1,
            duration: 0.7,
            ease: 'power1.out'
        })
        gsap.to(this.txt, {
            autoAlpha: 1,
            duration: 0.7,
            ease: 'power1.out',
            delay: _stagger,
            onComplete: () => {
                if (callback) callback()
            }
        })
    }
    disappearText(callback) {
        gsap.to(this.logo, {
            autoAlpha: 0,
            duration: 0.3,
            ease: 'power1.out'
        })
        gsap.to(this.txt, {
            autoAlpha: 0,
            duration: 0.3,
            ease: 'power1.out',
            onComplete: () => {
                if (callback) callback()
            }
        })
    }
}