import gsap from 'gsap';
import Card from './Card'
  
export default class PastCard extends Card {
    constructor(view, idx, eventDispatcher) {
        super(view, idx, eventDispatcher)
        this.view = view;
        this.idx = idx;
        this.eventDispatcher = eventDispatcher;

        this.background = this.view.querySelector('.asset');

        this.questText = this.view.querySelectorAll('.question-container .question');
        this.answerText = this.view.querySelectorAll('.answer-container .button-answer');

        this.visibleQuestText = [];
        this.visibleAnswerText = [];

        this.isCanPlayVideo = true;

        this.createSelectAnswer()
    }

    addListenerDispatchEvent() {
        this.eventDispatcher.addListener('_suspendVideo', this._suspendVideo.bind(this));
        this.eventDispatcher.addListener('_playVideo', this._playVideo.bind(this));
    }
    
    cardReady() {
        let isComplete = false;
        let newImg;
            
        const callback = () => {
            if (isComplete) return;
            isComplete = true;
            if (newImg) {
                newImg.remove()
            }
            this.eventDispatcher.dispatch('_past_cardReady', '3');
        }

        if (this.idx == 1) {
            this.background = document.querySelector('#mbti-past .background-question .asset');
        }

        if (this.background !== '' && this.background !== null) {

            if (this.background.tagName == 'VIDEO') {

                if(!document.body.classList.contains('hide-video')) {
                    const video = this.background;
                    video.load()
                    const videoLoaded = new Promise(function(resolve, reject) {
                        video.addEventListener('canplaythrough', function() {
                            resolve();
                        });
                    });
                    Promise.all([videoLoaded]).then(callback);
                } else {
                    gsap.delayedCall(1, () => callback())
                }
            } else {
                const image = this.background;
                newImg = new Image()
                newImg.src = image.src;
                const imageLoaded_load = new Promise(function(resolve, reject) {
                    newImg.addEventListener('load', function() {
                        resolve();
                    });
                });
                const imageLoaded_complete = new Promise(function(resolve, reject) {
                    newImg.addEventListener('complete', function() {
                        resolve();
                    });
                });
                Promise.all([imageLoaded_load]).then(callback);
                Promise.all([imageLoaded_complete]).then(callback);
            }
        } else {
            if (callback) callback()
            
        }
    }
    cardOn(delay) {

        gsap.killTweensOf(this.view, 'display')
        gsap.set(this.view, {
            display: 'block',
            pointerEvents: 'auto',
            onComplete: () => {
                if (this.idx == 0) {
                    this.appearBackground(delay)
                } else {
                    gsap.delayedCall(delay, () => {
                        this.appearQuestText()
                    })
                }
            }
        })
    }
    cardOff(val, callback) {
        gsap.killTweensOf(this.view, 'display')
        gsap.set(this.view, {
            pointerEvents: 'none'
        })

        if (val == 0) {
            this.resetCard()
        } else {
            gsap.delayedCall(0.2, () => {
                this.disappearQuestText()
                this.disappearAnswerText(callback)
            })
        }
    }
    appearBackground(delay) {
        const that = this;
        gsap.set(this.background, {
            autoAlpha: 1,
            onComplete: () => {
                gsap.delayedCall(delay, () => {
                    this.appearQuestText()
                })
                
                if (this.background.tagName == 'VIDEO') {
       
                    if (this.background.paused) {
                        this.background.play().then(() => {
                            that._playVideo()
                        }).catch((e) =>{
                            that._suspendVideo()
                        })
                    }
                }

            }
        })
        gsap.from(this.background, {
            xPercent: 2,
            duration: 1.8,
            ease: 'power2.out',
            onUpdate: function() {},
            onStart: () => {
             
            }
        })
    }
    disappearBackground(callback) {

        gsap.to(this.background, {
            xPercent: -2,
            duration: 1.5,
            ease: 'power1.out'
        })
        gsap.to(this.background, {
            autoAlpha: 0,
            duration: 1,
            ease: 'power1.inOut',
            onComplete: () => {
                if (callback) callback()
                this.resetCard()
            }
        })
    }

    createSelectAnswer() {
        this.answerText.forEach((answer, answerIdx) => {
            answer.onclick = () => {
                // if (document.body.classList.contains('is-mobile')) {
                //     if (answer.classList.contains('is-click')) {
                //         answer.classList.add('is-click')
                //         gsap.delayedCall(0.25, () => {
                //             answer.classList.remove('is-click')
                //         })
                //     }
                // }
                this._selectAnswer(answerIdx)
            }
        })
    }
    _selectAnswer(answerIdx) {
        this.eventDispatcher.dispatch('_past_selectAnswer', answerIdx);
    }

    updateQuest(questValArr, answerValArr) {

        this.visibleQuestText = [];
        this.questText.forEach((_title, _index) => {
            if (questValArr.length - 1 < _index) {
                gsap.set(_title, { display: 'none' })
                _title.querySelector('.quest-text').innerHTML = '';
                
            } else {
                gsap.set(_title, { display: 'block' })
                _title.querySelector('.quest-text').innerHTML = questValArr[_index]
       
                _title.setAttribute('data-width', _title.getBoundingClientRect().width)
                this.visibleQuestText.push(_title)
            }
        })

        this.visibleAnswerText = [];
        this.answerText.forEach((_title, _index) => {
            gsap.set(_title.querySelector('.button-background'), { scaleX: this.sx, opacity: 0 })
            gsap.set(_title.querySelector('.answer-text'), { opacity: 0 })

            if (answerValArr.length - 1 < _index) {
                gsap.set(_title, { display: 'none' })
  
                _title.querySelector('.answer-text').innerHTML = '';
            } else {
                gsap.set(_title, { display: 'block' })
                _title.querySelector('.answer-text').innerHTML = answerValArr[_index]
                this.visibleAnswerText.push(_title)
            }
        })
    }
    resetCard(){
        
        gsap.set(this.view, { display: 'none' })

        gsap.killTweensOf(this.questText)
        gsap.killTweensOf(this.answerText)

        gsap.killTweensOf(gsap.utils.toArray('.answer-container .button-answer', this.view))
        gsap.killTweensOf(gsap.utils.toArray('.answer-container .button-background', this.view))
        gsap.killTweensOf(gsap.utils.toArray('.answer-container .answer-text', this.view))

        gsap.set(gsap.utils.toArray('.question-container .question', this.view), { clearProps: 'all', display: 'none' })
        gsap.set(gsap.utils.toArray('.question-container .question .quest-background', this.view), { clearProps: 'all', autoAlpha: 0 })
        gsap.set(gsap.utils.toArray('.question-container .question .quest-text', this.view), { clearProps: 'all', autoAlpha: 0 })
        gsap.set(gsap.utils.toArray('.answer-container .button-answer', this.view), { clearProps: 'all', display: 'none' })
        gsap.set(gsap.utils.toArray('.button-background', this.view), { clearProps: 'all', scaleX: this.sx, opacity: 0 })
        gsap.set(gsap.utils.toArray('.answer-text', this.view), { clearProps: 'all', opacity: 0 })

        if (this.background && this.background.tagName == 'VIDEO') {
            if(!this.background.paused) {
                this.background.pause()
            }
            this.background.currentTime = 0;
        }
    }
}
