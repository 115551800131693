import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Card from './Card';


export default class CardResult extends Card {
    constructor(view, idx, eventDispatcher) {

        gsap.registerPlugin(ScrollTrigger,ScrollToPlugin)
        ScrollTrigger.config({ autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load' })

        super(view, idx, eventDispatcher)

        this.view = view;

        this.idx = idx;
        this.eventDispatcher = eventDispatcher;
        this.frameWrap = this.view.querySelector('.result-image-wrap')
        this.frame = this.view.querySelector('.frame')
        this.background = this.view.querySelector('.image-main')
        this.image = this.view.querySelector('.image-wrap');

        this.content_yv = 0;
        this.yv = 60;
        this.init()
        
        this.triggers = []
        // transition property
        this.initSetStyle()

        // this.test()
    }

    resetImage() {
        this.initSetStyle()
    }
    test() {

    }
    init() {
        this.createRestartEvent()
        this.createOtherStartEvent()
        this.createShareEvent()
    }
    createRestartEvent() {
        this.view.querySelector('.button-restart').addEventListener('click', () => {
            this.eventDispatcher.dispatch('_restartGame', '');
        })
    }
    createOtherStartEvent() {
        gsap.utils.toArray('.button-another', this.view).forEach(button => {
            button.addEventListener('click', () => {
                this.eventDispatcher.dispatch('_otherGameStart', '');
            })
        })
    }
    createShareEvent() {
        this.view.querySelector('.button-share').addEventListener('click', () => {
            this.eventDispatcher.dispatch('_gameShare', {
                result_title: this.result_title,
                result_description: this.result_description,
                result_image: this.result_image
            });
        })

        this.view.querySelector('.button-share-kakao').addEventListener('click', () => {
            this.eventDispatcher.dispatch('_gameShareKakao', {
                result_title: this.result_title,
                result_description: this.result_description,
                result_image: this.result_image
            });
        })
    }

    cardOn(delay) {
        gsap.set(this.view, {
            display: 'block',
            pointerEvents: 'auto',
            onComplete: () => {
                if (this.background.tagName == 'VIDEO' && this.background.paused){
                    if(!document.body.classList.contains('hide-video')) {
                        this.background.play()
                        this.background.currentTime = 0.01
                        this.background.pause()
                    }
                }
                gsap.to(this.view, { scrollTo: { y: 4000 }, duration: 0.1, onComplete: () => {
                    gsap.set(this.view, { scrollTo: { y: 0 }})
                    // gsap.to(this.view.querySelector('.content'), { height: 'auto' , duration: 0.1 })
                } })
                // this.eventDispatcher.dispatch('_fadeInDim', { duration: 1, delay: 0 })
                gsap.delayedCall(delay, () => {
                    this.intro()
                    this.eventDispatcher.dispatch('_enablleClick')
                })
            }
        })
    }
    createScrollTrigger() {
        ScrollTrigger.update()
        

        gsap.utils.toArray('.el', this.view).forEach((el, idx) => {
            const trigger = ScrollTrigger.create({
                trigger: el,
                start: () => `top bottom-=1%`,
                scroller: this.view,
                refreshPriority: 1,
                invalidateOnRefresh: true,
                // markers: true,
                onToggle: (self) => { 
                    if (self.isActive) {
                        if (el.hasAttribute('data-load')) return;
                        el.setAttribute('data-load', true)
                        gsap.to(el, { autoAlpha: 1, duration: 0.7, ease: 'power1.out' })
                        gsap.to(el, { y: 0, duration: 0.7, ease: 'power2.out' })
                        gsap.to(el.querySelector('.text-inner'), { autoAlpha: 1, duration: 0.9, ease: 'power1.out', delay: 0.2 })
                    }
                }
            })

            this.triggers.push(trigger);
        })

        this.createContentScrollTrigger()
    }
    updateFunction() {
        gsap.set([this.frame, this.image], {
            filter: `contrast(${this.contrastValue}) brightness(${this.brightnessValue})`
        })
    }
    initSetStyle() {
        this.contrastValue = 3;
        this.brightnessValue = 0;
        this.updateFunction()
        gsap.set(this.frameWrap, { scale: 0.01, y: 60 })
        gsap.set(gsap.utils.toArray('.el', this.view), { y: this.yv, autoAlpha: 0 })
        gsap.set(this.view.querySelector('.content-inner'), { y: this.content_yv, opacity: 0 })
    }

    intro() {
        gsap.to(this.view, { pointerEvents: 'auto', duration: 0.2 })
        gsap.to(this, {
            contrastValue: 1.2,
            duration: 3,
            ease: 'power1.inOut',
            delay: 0.1,
            onUpdate: () => this.updateFunction()
        })
        gsap.to(this, {
            brightnessValue: 0.9,
            duration: 2.5,
            ease: 'power1.inOut',
            delay: 0.1,
            onUpdate: () => this.updateFunction()
        })
        gsap.to(this.frameWrap, {
            scale: 1,
            duration: 4,
            ease: 'power1.out',
            onComplete: () => {
                gsap.to(this.frameWrap.querySelector('.logo-text'), {
                    opacity: 1,
                    duration: 0.4,
                    ease: 'power1.inOut'
                })
            }
        })
        gsap.to(this.frameWrap, {
            y: 0,
            duration: 4,
            ease: 'power2.inOut',
            delay: 0.5,
            onComplete: () => {

                gsap.delayedCall(0.5, () => {
                    if (this.background.tagName == 'VIDEO' && this.background.paused){
                        if(!document.body.classList.contains('hide-video')) {
                            this.background.play()
                        }
                    }
                })

                
            }
        })

        // 텍스트 먼저 -> 시작하고 0.5 초 후에 동영상 플레이
        this.createScrollTrigger()
        this.view.click()
        gsap.to(this.view, { scrollTo: { y: 0 }, duration: 0.1, onComplete: () => {
            this.view.click()
        } })
        gsap.delayedCall(4.5, () => {
            this.eventDispatcher.dispatch('_enableClick', '')
            this.view.click()
        })

        gsap.to(this.view.querySelector('.content-inner'), {
            opacity: 1,
            duration: 1.2,
            ease: 'power1.inOut',
            delay: 1.8,
        })
        gsap.to(this.view.querySelector('.content-inner'), {
            y: 0,
            duration: 1.2,
            ease: 'power2.out',
            delay: 1.8,
            onComplete: () => {
                ScrollTrigger.refresh()
            }
        })
        this.test()
        const st_content = ScrollTrigger.create({
            trigger: this.view.querySelector('.content-inner'),
            scroller: this.view,
            start: () => `70% bottom`,
            once: true,
            onEnter: () => {
                
                gsap.to(this.view.querySelector('.content-inner'), {
                    opacity: 1,
                    duration: 1.2,
                    ease: 'power1.inOut',
                })
                gsap.to(this.view.querySelector('.content-inner'), {
                    y: 0,
                    duration: 1.2,
                    ease: 'power2.out',
                })
            }
        })

        this.triggers.push(st_content);

        if (document.body.classList.contains('is-mobile')) {
            this.appearArrow(3.2)
            
            ScrollTrigger.create({
                trigger: this.view,
                scroller: this.view,
                start: () => `top+=1 top`,
                once: true,
                onEnter: () => {
                    this.disappearArrow()
                }
            })
        }
    }

    appearArrow(delay) {
        const arrow = this.view.querySelector('.scroll-detect .ico-arrow');
        gsap.to(arrow, { 
            y: 0, 
            duration: 0.9, 
            ease: 'power1.inOut', 
            delay: 0, 
            yoyo: true, 
            repeat: -1,
            delay: delay - 0.3
        });
        gsap.to(arrow, { 
            opacity: 0.6, 
            duration: 0.6,
            ease: 'power1.out',
            delay: delay
        })
    }

    disappearArrow() {

        const arrowContainer = this.view.querySelector('.scroll-detect');
        const arrow = this.view.querySelector('.scroll-detect .ico-arrow');

        gsap.to(arrowContainer, { 
            autoAlpha: 0, 
            duration: 0.4,
            ease: 'power1.out',
            onComplete: () => {
                gsap.killTweensOf(arrow)
            }
        })
    }

    resetCard(){}

    createContentScrollTrigger() {}
}
