import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default class Bridge {
    constructor(view) {
        this.view = view;

        this.image = this.view.querySelector('.main-image')
        gsap.registerPlugin(gsap, ScrollTrigger);
        ScrollTrigger.clearScrollMemory()
        
        this.cardOn()
    }

    // 초기 상태값 설정
    initSetStyle() {
        // main-content
        this.contrastValue = 3;
        this.brightnessValue = 0;
        this.updateFunction()
        gsap.set(this.view.querySelector('.main-content .text-title'), { opacity: 0, y: 70 })

        // 한문장의~~
        gsap.set(this.view.querySelector('.intro-title .title-main'), { opacity: 0 })
        gsap.set(this.view.querySelector('.intro-title .circle'), { opacity: 0, scale: 0.5 })
        gsap.set(this.view.querySelector('.intro-title .line'), { opacity: 0, scaleY: 0.5 })

        // prompt
        gsap.set(this.view.querySelectorAll('.prompt-list .image'), { opacity: 0 })
        gsap.set(this.view.querySelectorAll('.prompt-list .info'), { opacity: 0, y: 50 })

        // grid
        gsap.utils.toArray('.grid-col', this.view).forEach((grid, i) => {
            gsap.set(grid, { opacity: 0, y: i % 2 == 0 ? 40 : -40 })
        })

        // 누구나. 손쉽게. 무료로.
        gsap.set(this.view.querySelectorAll('.feature-list .feature-image'), { opacity: 0 })
        gsap.set(this.view.querySelectorAll('.feature-list .feature-text .text-title'), { opacity: 0 })
        gsap.set(this.view.querySelectorAll('.feature-list .feature-text .text-sub'), { opacity: 0 })
        gsap.set(this.view.querySelectorAll('.button-background'), { opacity: 0, scaleX: 0.2 })
        gsap.set(this.view.querySelectorAll('.answer-text'), { opacity: 0 })


        // 어도비
        gsap.set(this.view.querySelector('.icon-adobe-ci'), { opacity: 0 })
        gsap.set(this.view.querySelectorAll('.adobe-info .el'), { opacity: 0, y: 30 })
        gsap.set(this.view.querySelectorAll('.adobe-info .line-black'), { y: 0 })
        gsap.set(this.view.querySelectorAll('.adobe-info .image'), { opacity: 0 })
        gsap.set(this.view.querySelectorAll('.adobe-info .text-title'), { opacity: 0 })
    }

    cardOn() {

        gsap.set(this.view, { display: 'block', pointerEvents: 'auto', onComplete: () => {
            this.initSetStyle()
            gsap.delayedCall(0.2, () => {
                this.intro()
            })
        } })

    }

    createButtonTrigger(button) {
        ScrollTrigger.create({
            trigger: button,
            start: () => `top bottom`,
            scroller: this.view,
            // markers: true,
            refreshPriority: 0,
            invalidateOnRefresh: true,
            once: true,
            onEnter: () => {
                this.appearButton(button, 0)
            }
        })
    }


    appearButtonLarge(button, delay) {
        // 버튼
        const button_delay = delay ?? 0;
            
        gsap.to(button.querySelector('.button-background'), { 
            scaleX: 1, 
            duration: 0.75,
            ease: 'power2.out',
            delay: button_delay,
        })
        // Background : opacity
        gsap.to(button.querySelector('.button-background'), { 
            opacity: 1, 
            duration: 0.65,
            ease: 'power1.inOut',
            delay: button_delay,
        })
        // Background : text
        gsap.to(button.querySelector('.answer-text'), { 
            opacity: 1,
            duration: 0.45,
            ease: 'power1.out',
            delay: button_delay + 0.45,
        })
    }

    appearButton(button, delay) {
        // 버튼
        const button_delay = delay ?? 0;
            
        gsap.to(button.querySelector('.button-background'), { 
            scaleX: 1, 
            duration: 0.45,
            ease: 'power1.out',
            delay: button_delay,
        })
        // Background : opacity
        gsap.to(button.querySelector('.button-background'), { 
            opacity: 1, 
            duration: 0.40,
            ease: 'power1.inOut',
            delay: button_delay,
        })
        // Background : text
        gsap.to(button.querySelector('.answer-text'), { 
            opacity: 1,
            duration: 0.35,
            ease: 'power1.out',
            delay: button_delay + 0.35,
        })
    }

    updateFunction() {
        gsap.set(this.image, {
            filter: `contrast(${this.contrastValue}) brightness(${this.brightnessValue})`
        })
    }
    
    intro() {
        // hero
        gsap.to(this, {
            contrastValue: 1.2,
            duration: 2.5,
            ease: 'power2.inOut',
            delay: 0.1,
            onUpdate: () => this.updateFunction()
        })
        gsap.to(this, {
            brightnessValue: 0.9,
            duration: 2.0,
            ease: 'power3.inOut',
            delay: 0.1,
            onUpdate: () => this.updateFunction()
        })
        gsap.to(this.view.querySelector('.main-content .text-title'), {
            opacity: 1, 
            duration: 1.0,
            ease: 'power1.inOut',
            delay: 1.4,
        })
        gsap.to(this.view.querySelector('.main-content .text-title'), {
            y: 0, 
            duration: 1.1,
            ease: 'power2.out',
            delay: 1.4,
        })

        this.appearButtonLarge(this.view.querySelector('.main-content .button-pink'), 1.95)



        // 한문장의~
        ScrollTrigger.create({
            trigger: this.view.querySelector('.intro-title .title-main'),
            start: () => `top bottom`,
            scroller: this.view,
            // markers: true,
            refreshPriority: 0,
            invalidateOnRefresh: true,
            onEnter: () => {
                gsap.to(this.view.querySelector('.intro-title .circle'), {
                    opacity: 1, 
                    duration: 1,
                    ease: 'power1.inOut',
                    delay: 0
                })
                gsap.to(this.view.querySelector('.intro-title .title-main'), {
                    opacity: 1, 
                    duration: 1,
                    ease: 'power1.inOut',
                    delay: 0.2
                })
                gsap.to(this.view.querySelector('.intro-title .title-main'), {
                    y: 0, 
                    duration: 1,
                    ease: 'power2.out',
                    delay: 0.2
                })
                gsap.to(this.view.querySelector('.intro-title .line'), {
                    opacity: 1, 
                    duration: 0.4,
                    ease: 'power1.inOut',
                    delay: 0.6
                })
                gsap.from(this.view.querySelector('.intro-title .line'), {
                    y: -40, 
                    duration: 2,
                    ease: 'power1.out',
                    delay: 0.6
                })
                gsap.to(this.view.querySelector('.intro-title .line'), {
                    scaleY: 1, 
                    duration: 2,
                    ease: 'power1.out',
                    delay: 0.6
                })
                gsap.to(this.view.querySelectorAll('.text-pink'), {
                    backgroundPosition: "0% 0%",
                    duration: 1.4,
                    ease: "power2.out",
                    delay: 0.1,
                    stagger: 0.1
                })
            }
        })

        
        // 


        gsap.utils.toArray('.prompt-list', this.view).forEach((prompt, i) => {
            ScrollTrigger.create({
                trigger: prompt,
                scroller: this.view,
                start: () => `top bottom`,
                once: true,
                onEnter: () => {
                    gsap.to(prompt.querySelector('.image'), {
                        opacity: 1, 
                        duration: 1,
                        ease: 'power1.inOut',
                        delay: 0
                    })
                    gsap.to(prompt.querySelector('.info'), {
                        opacity: 1, 
                        duration: 1,
                        ease: 'power1.inOut',
                        delay: 0.4
                    })
                    gsap.to(prompt.querySelector('.info'), {
                        y: 0, 
                        duration: 1,
                        ease: 'power2.out',
                        delay: 0.4
                    })
                }
            })
        })

        // 8장 이미지
        ScrollTrigger.create({
            trigger: this.view.querySelector('.result-grid'),
            scroller: this.view,
            start: () => `top bottom`,
            once: true,
            onEnter: () => {
                gsap.to(this.view.querySelectorAll('.grid-col'), {
                    opacity: 1, 
                    duration: 0.8,
                    ease: 'power1.inOut',
                    delay: 0,
                    stagger: 0.1
                })
                gsap.to(this.view.querySelectorAll('.grid-col'), {
                    y: 0, 
                    duration: 1.4,
                    ease: 'power1.inOut',
                    delay: 0,
                    stagger: 0.1
                })
            }
        })

        // 누구나. 손쉽게. 무료로.
        gsap.utils.toArray('.feature-list', this.view).forEach((feature, idx) => {
            ScrollTrigger.create({
                trigger: feature,
                scroller: this.view,
                start: () => `top bottom`,
                once: true,
                onEnter: () => {
                    gsap.to(feature.querySelector('.feature-image'), {
                        opacity: 1, 
                        duration: 0.8,
                        ease: 'power1.inOut',
                        delay: 0,
                    })
                    gsap.to(feature.querySelector('.text-title'), {
                        opacity: 1, 
                        duration: 0.8,
                        ease: 'power1.inOut',
                        delay: 0.4,
                    })
                    gsap.to(feature.querySelector('.text-sub'), {
                        opacity: 1, 
                        duration: 0.8,
                        ease: 'power1.inOut',
                        delay: 0.4,
                    })
                }
            })
            // 버튼
            this.createButtonTrigger(feature.querySelector('.button-pink'))
        })

        gsap.utils.toArray('.adobe-info .el', this.view).forEach((el, idx) => {
            ScrollTrigger.create({
                trigger: el,
                scroller: this.view,
                start: () => `top bottom`,
                once: true,
                onEnter: () => {
                    gsap.to(el, {
                        opacity: 1, 
                        duration: 0.8,
                        ease: 'power1.inOut',
                        delay: 0,
                    })
                    gsap.to(el, {
                        y: 0, 
                        duration: 0.8,
                        ease: 'power2.out',
                        delay: 0,
                    })
                }
            })
        })

        this.createButtonTrigger(this.view.querySelector('.button-single'), 0)


        // 어도비
        gsap.utils.toArray('.adobe-info .feature', this.view).forEach((feature, idx) => {
            ScrollTrigger.create({
                trigger: feature,
                scroller: this.view,
                start: () => `top bottom`,
                once: true,
                onEnter: () => {
                    gsap.to(feature.querySelector('.image'), {
                        opacity: 1, 
                        duration: 0.8,
                        ease: 'power1.inOut',
                        delay: 0,
                    })
                    gsap.to(feature.querySelector('.text-title'), {
                        opacity: 1, 
                        duration: 0.8,
                        ease: 'power1.inOut',
                        delay: 0.4,
                    })
  
                    // 버튼
                    
                }
            })

            this.createButtonTrigger(feature.querySelector('.button-blue'))
        })

        ScrollTrigger.create({
            trigger: this.view.querySelector('.adobe-info'),
            scroller: this.view,
            start: () => `top-=13 top`,
            onToggle: (self) => {
                if (self.isActive) {
                    document.querySelector('header').classList.add('theme-light')
                } else {
                    document.querySelector('header').classList.remove('theme-light')
                }
            }
        })

        ScrollTrigger.refresh()
    }
}