import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CardResult from './CardResult';
import { domToJpeg, domToDataUrl } from 'modern-screenshot'
import Lottie from 'lottie-web';

export default class FlowerCardResult extends CardResult {
    constructor(view, idx, eventDispatcher, content, background) {
        super(view, idx, eventDispatcher)

        gsap.registerPlugin(ScrollTrigger)
        ScrollTrigger.config({ autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load' })

        this.view = view;
        this.idx = idx;
        this.eventDispatcher = eventDispatcher;
        this.loadData = false;
        this.dataURL = '';
        this.dataContext = '';
        this.linkClickEvent = this.openImage.bind(this)
        this._window = null;

        // this.inAppBrowser = window.navigator.userAgent.includes('Line');
        this.inAppBrowser = true;

        this.result_title = '';
        this.result_description = '';
        this.result_image = '';
    }
    cardReady() {
        this.dataURL = ''
        if (this.loadData) {

            let isComplete = false;
            let newImg;

            const callback = () => {
                if (isComplete) return;
                isComplete = true;
                if (newImg) {
                    newImg.remove()
                }
                this.eventDispatcher.dispatch('_flower_cardReady', 'result');    
            }

            if (this.view.querySelector('[data-type=video]') && this.view.querySelector('[data-type=video]').src) {
                if(!document.body.classList.contains('hide-video')) {

                    const video = this.view.querySelector('[data-type=video]');
                    video.load()
                    const videoLoaded = new Promise(function(resolve, reject) {
                        video.addEventListener('canplaythrough', function() {
                            resolve();
                        });
                    });
                    Promise.all([videoLoaded]).then(callback);
                } else {
                    gsap.delayedCall(1, () => callback())
                }
            } 
            if (this.view.querySelector('[data-type=main_image]') && this.view.querySelector('[data-type=main_image]').src) {
                const image = this.view.querySelector('[data-type=main_image]');
                newImg = new Image()
                newImg.src = image.src;

                const imageLoaded_load = new Promise(function(resolve, reject) {
                    newImg.addEventListener('load', function() {
                        resolve();
                    });
                });
                const imageLoaded_complete = new Promise(function(resolve, reject) {
                    newImg.addEventListener('complete', function() {
                        resolve();
                    });
                });
                Promise.all([imageLoaded_load]).then(callback);
                Promise.all([imageLoaded_complete]).then(callback);
            }
        }

    }
    sendResultData(res) {
        this.view.scrollTop = 0;

        const { result_title, result_description } = res;
        this.result_title = result_title
        this.result_description = result_description
        this.result_image = res['main_image']
        
        Object.entries(res).map(r => {
            const [ key, value ] = r;
            if (key == 'positive' || key == 'negative') {
   
                const container = this.view.querySelector('[data-type=combi]');
                if (container) {
                    container.innerHTML = '';
                }
                const sub = key == 'positive' ? '환상의 궁합' : '희대의 상극';
    
                value.forEach(_val => {
                    const { title, thumbnail } = _val;
                    const content = document.createElement('div');
    
                    content.classList.add('combi')
                    content.innerHTML = `
                        <div class="thumbnail">
                            <img src=${thumbnail} alt="">
                        </div>
                        <div class="text">
                            <div class="text-sub">${sub}</div>
                            <div class="text-title">${title}</div>
                        </div>`;
    
                    container.appendChild(content)
                })
            } 
            if (key == 'conversation' || key == 'achievements') {
                const container = this.view.querySelector(`.result-${key}`)
                container.innerHTML = '';
                const contents = gsap.utils.toArray(`[data-type=${key}]`, container);
    
                value.map((v, i) => {
                    let content = contents[i]
                    content.innerHTML = '';
    
                    if (!content) {
                        // if (i >= contents.length) {
                            // alert(i)
                        content = document.createElement('div')
                        content.classList.add('frame-rect')
                        content.classList.add('el')
                        content.setAttribute('data-type', key);
                        container.appendChild(content)
                        // }
                    }
                    // 갯수 차감
    
                    let _v = v;
    
                    if (_v.includes('\n')) {
                        _v = `<div class="text-inner">${v.split('\n').join('<br />')}</div>`
                    }
    
                    
                    content.innerHTML = _v;
                })
            } 
            if (key == 'summary' || key == 'later_story' || key == 'title' || key == 'profile') {
                this.view.querySelector(`[data-type=${key}]`).innerHTML = ''
                this.view.querySelector(`[data-type=${key}]`).innerHTML = value.split('\n').join('<br />')
            }
            if (key == 'main_image') {
                this.view.querySelector(`[data-type=${key}]`).src = '';
                this.view.querySelector(`[data-type=${key}]`).src = `${value}?imestamp=${new Date().getTime()}`
                this.loadData = true;
                this.cardReady()
            }
            
        })
        this.initSetStyle()
        this.createScreenshot(res)
    }

    createScreenshot(res) {

        if (document.querySelector('.screenshot')) {
            document.querySelector('.screenshot').remove()
        }
        const result = document.createElement('div');
        result.classList.add('container')
        result.classList.add('result')
        result.classList.add('flower')
        result.classList.add('screenshot')

        result.innerHTML = `
        <div class="content">
            <div class="result-image-wrap">
                <span class="frame">
                    <span class="frame-inner"></span>
                    <img src="https://aim.plus-ex.com/images/flower/frame.png?timestamp=${new Date().getTime()}" alt="">
                </span>
                <div class="image">
                <span class="image-wrap">
                    <img class="image-frame" src="https://aim.plus-ex.com/images/1aaaa.jpg?timestamp=${new Date().getTime()}" alt="">
                    <img class="result-image image-main" data-type="main_image" crossorigin="anonymous" src="${res['main_image']}?timestamp=${new Date().getTime()}" alt="">
                    </span>
                    <span class="logo-text">Powered by Adobe Firefly</span>
                </div>
            </div>
            <div class="content-inner">
                <div class="result-text">
                    ${res['title'] ? `<div class="text-title" data-type="title">${res['title'].split('\n').join('<br />')}</div>` : ''}
                    ${res['profile'] ? `<div class="text-profile" data-type="profile">${res['profile'].split('\n').join('<br />')}</div>` : ''}
                </div>
            </div>
        </div>`;


        this.result = result;
        const link = this.view.querySelector('.button-download');
        link.addEventListener('click', this.linkClickEvent)

    }
    createScreenshot_url(init) {
        if (this.dataURL !== '') return;
        const that = this;
        
        document.body.appendChild(this.result);
        document.body.classList.add('is-capture')
        gsap.set(this.result, { opacity: 1 })
     
        let isLoaded = false;
        let _scale = document.body.classList.contains('is-mobile') ? 1.5 : 1.5;
        let _quality = document.body.classList.contains('is-mobile') ? 1 : 0.5;
        const callback = () => {
            if (isLoaded) return;
            isLoaded  = true;
            domToJpeg(this.result, { scale: _scale,
             }).then(dataUrl => {
                that.changeDATA(dataUrl)
                gsap.set(this.result, { opacity: 0 })
                gsap.delayedCall(0.1, () => {
                    if (init) {
                        if (this.inAppBrowser) {
                            this.openResultImage_inapp()
                        } else {
                            if (this._window.document) {
                                this.openResultImage(true)
                            } else {
                                this.inAppBrowser = true;
                                this.openResultImage_inapp()
                            }
                        }
                    }
                })
            })
        }
        const newImg = new Image()
        newImg.crossOrigin = 'anonymous';
        newImg.src = document.querySelector('.screenshot .result-image').getAttribute('src')

        const imageLoaded_load = new Promise(function(resolve, reject) {
            newImg.addEventListener('load', function() {
                resolve();
            });
        });
        const imageLoaded_complete = new Promise(function(resolve, reject) {
            newImg.addEventListener('complete', function() {
                resolve();
            });
        });
        Promise.all([imageLoaded_load]).then(callback);
        Promise.all([imageLoaded_complete]).then(callback);
        
    }

    openImage() {
        if (this.dataURL !== '') {
            if (this.inAppBrowser) {
                const callback = () => this.openResultImage_inapp()
                this.openResultImage_inapp_loading(callback)
            } else {
                this.openResultImage()
            }
        } else {
            if (this.inAppBrowser) {
                this.openResultImage_inapp_loading()
                gsap.delayedCall(1.0, () => {
                    this.createScreenshot_url(true)
                })
            } else {
                this._window = window.open('https://aim.plus-ex.com/download/', '_blank');
                this.createScreenshot_url(true)
            }
            
        }
    }
    changeDATA(rul) {
        this.dataURL = rul;  
    }
    openResultImage(init) {
        if (document.querySelector('.screenshot')) {
            document.querySelector('.screenshot').remove()
        }
        if (!init) {
            this._window = this._window.close()
            this._window = window.open('https://aim.plus-ex.com/download/', '_blank');
        }
        
        setTimeout(() => {
            const image = new Image();
            image.src = this.dataURL;
            image.style.width = '100%';
            this._window.document.write(image.outerHTML);
            gsap.set(this._window.document.body, { backgroundColor: '#000', margin: 0, padding: 0})
        }, 120)
    }

    // 라인 인앱 분기처리
    openResultImage_inapp() {        
        if (document.querySelector('.layer-inapp')) {
            const layer = document.querySelector('.layer-inapp')
            const image = document.createElement('img')
            image.crossOrigin = 'anonymous';
            image.src = this.dataURL;
            image.style.width = '100%';
            
            const callback = () => layer.appendChild(image)
            this.eventDispatcher.dispatch('_readyDownloadLayer', { _callback : callback });
  
            layer.querySelector('.button-close').addEventListener('click', () => {
                this.eventDispatcher.dispatch('_disappearDownloadLayer');
            }, { once: true } )
        } 
    }

    openResultImage_inapp_loading(callback) {
        this.eventDispatcher.dispatch('_appearDownloadLayer', { _callback : callback });
    }
    resetCard(){
        this.loadData = false;
        gsap.set(this.view, { scrollTop: { y: 0 }, onComplete: () => {
            gsap.set(this.view, { display: 'none' })
            this.resetImage()
        } })

        if (this.triggers) {
            this.triggers.forEach((tr, i) => {
                tr.kill()
                if (i == this.triggers.length - 1) {
                    this.triggers = []
                }
            })
        }
        gsap.killTweensOf(gsap.utils.toArray('.el', this.view))
        gsap.killTweensOf(this.background)

        gsap.killTweensOf(gsap.utils.toArray('.text-inner', this.view), { clearProps: 'all', autoAlpha: 0 })
        
        gsap.killTweensOf(gsap.utils.toArray('.el .text-inner', this.view))
        gsap.killTweensOf(gsap.utils.toArray('.el', this.view), { clearProps: 'all', autoAlpha: 0, y: this.yv })

        gsap.killTweensOf(gsap.utils.toArray('.result-text div[data-type]', this.view))
        gsap.killTweensOf(gsap.utils.toArray('.result-text div[data-type]', this.view), { clearProps: 'all' })
        // gsap.killTweensOf(this.view.querySelector('.adobe'), { clearProps: 'all' })
        gsap.killTweensOf(this.view.querySelector('.adobe img'), { clearProps: 'all' })
        // if (this.background) {
        //     this.background.src = '';
        // }v
        gsap.set(gsap.utils.toArray('.content-inner', this.view), { clearProps: 'all', opacity: 0, y: this.content_yv })
        gsap.killTweensOf(this.view.querySelector('.result-text div[data-type]'))
        gsap.killTweensOf(this.view.querySelector('.result-text div[data-type]'), { clearProps: 'all', autoAlpha: 0 })
        gsap.to(gsap.utils.toArray('.el', this.view), { autoAlpha: 0, y: 60, duration: 0.1 })
        gsap.utils.toArray('.el', this.view).forEach(e => {
            if (e.hasAttribute('data-load')) {
                e.removeAttribute('data-load')
            }
        })
        gsap.killTweensOf(this.frameWrap.querySelector('.logo-text'))
        gsap.to(this.frameWrap.querySelector('.logo-text'), { clearProps: 'all', opacity: 0, duration: 0.1 })

        gsap.killTweensOf(this.view.querySelector('.scroll-detect .ico-arrow'))
        gsap.to(this.view.querySelector('.scroll-detect .ico-arrow'), { clearProps: 'all', opacity: 0, y: -6, duration: 0.1 })
        gsap.killTweensOf(this.view.querySelector('.scroll-detect'))
        gsap.to(this.view.querySelector('.scroll-detect'), { clearProps: 'all', autoAlpha: 1 })

        if (document.querySelector('.screenshot')) {
            document.querySelector('.screenshot').remove()
        }
        document.body.classList.remove('is-capture')
        this.view.querySelector('.button-download').removeAttribute('data-href')
        this.dataURL = '';
        this.result = null;   
        this.view.querySelector('.button-download').removeEventListener('click', this.linkClickEvent)     
      

        if (document.querySelector('.layer-inapp img')) {
            document.querySelector('.layer-inapp img').remove()
        }

        this.result_title = '';
        this.result_description = '';
        this.result_image = '';
    }
}