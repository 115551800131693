import gsap from 'gsap';
import axios from 'axios';

import FlowerQuest from './FlowerQuest';

import FlowerCard from './FlowerCard';
import FlowerCardLoading from './FlowerCardLoading';
import FlowerCardResult from './FlowerCardResult';

export default class Flower {
    constructor(eventDispatcher) {
        this.form = document.querySelector('#mbti-flower');

        this.background = this.form.querySelector('.background-question')

        this.user = '';

        this.question = new FlowerQuest()
        this.maxQuest = this.question.questArray.length;

        this.cards = [];
        this.totalCard = 0;

        this.nowCardIdx = -1;
        this.eventDispatcher = eventDispatcher;

        this.url = 'https://api.aim.plus-ex.com/api/flower';

        this.init()

        this.testMode = false;

        this.fadeIn_duration = 1;
        this.fadeOut_duration = 1
        this.play_loading_duration = 1
        this.stop_loading_duration = 1

        this.minimum_delay = 1;
        this.is_result = true;
    }
    init() {
        this.addListenerDispatchEvent()
        this.createCard()        
    }
    // DispatchEvent
    addListenerDispatchEvent() {
        this.eventDispatcher.addListener('_flower_selectAnswer', this._selectAnswer.bind(this));
        this.eventDispatcher.addListener('_flower_cardReady', this._cardReady.bind(this));
    }
    createCard() {
        const container = this.form.querySelectorAll('.container');
        for (let i = 0; i < container.length - 2; i++) {
            const card = new FlowerCard(container[i], i, this.eventDispatcher);
            this.cards.push(card)
        }
  
        const flowerCardLoading = new FlowerCardLoading(container[container.length - 2], container.length - 2, this.eventDispatcher);
        this.cards.push(flowerCardLoading)
        const flowerCardResult = new FlowerCardResult(container[container.length - 1], container.length - 1, this.eventDispatcher);
        this.cards.push(flowerCardResult)

        // max card length
        this.totalCard = this.cards.length
    }
    _cardReady() {
        this._changeCard()
    }
    _selectAnswer(answerIdx, testMode) {
        this.testMode = testMode;
        if (this.nowCardIdx > 0 && this.maxQuest - 1) {
            this.user += String(answerIdx);
        }

        if (this.nowCardIdx > 0 && this.nowCardIdx < this.maxQuest) {
            const imageQuestion = this.question.bringResult(this.user);
            const loadURL = `https://aim-mbti-image.s3.ap-northeast-2.amazonaws.com/production/flower/background/${imageQuestion}.jpg`;
            this.cards[this.nowCardIdx + 1].setAttributeAsset(loadURL)
        }
        this.stepNextFlower(testMode)
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////// FadeIn Animation : All
    fadeIn_anim(alpha, callback) {
        // property
        this.fadeIn_duration = 1;
        this.fadeOut_duration = 1;

        const options = {
            _fadeInDim: {
                duration: 1,
                delay: 0,
            },
            _playLoading: {
                duration: 1,
                delay: 0
            },
        }

        options._fadeInDim.alpha = alpha ?? 1;

        this.eventDispatcher.dispatch('_fadeInDim', options._fadeInDim )
        this.eventDispatcher.dispatch('_playLoading', options._playLoading )

        if (callback) {
            gsap.delayedCall(options._fadeInDim.duration + options._fadeInDim.delay, () => callback())
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////// FadeOut Animation : [Loading → Result]], [Quest → Loading]
    fadeOut_anim(onStart, isLast) {
        const options = {
            _stopLoading: {
                duration: 0.7,
                delay: 0
            },
            _fadeOutDim: {
                duration: isLast ? 1 : 1.5,
                delay: 0,
            }
        }

        options._fadeOutDim.delay += options._stopLoading.duration

        this.eventDispatcher.dispatch('_stopLoading', options._stopLoading );
        this.eventDispatcher.dispatch('_fadeOutDim', options._fadeOutDim );

        if (onStart) {
            onStart(options._fadeOutDim.duration)
        }
    }
    //////////////////// FadeOut Animation : Quest - Quest
    quest_fadeOut_anim(onComplete) {
        const nextCardIdx = this.nowCardIdx + 1;
        const options = {
            _fadeInDimAlpha: {
                alpha: 1,
                duration: 1,
                delay: 0
            },
            _stopLoading: {
                duration: 0.4,
                delay: 0
            },
            _fadeOutDim: {
                duration: 1.5,
                delay: 0,
            }
        }

        options._fadeOutDim.delay += (options._fadeInDimAlpha.duration + options._fadeInDimAlpha.delay)

        this.eventDispatcher.dispatch('_fadeInDimAlpha', options._fadeInDimAlpha );
        this.eventDispatcher.dispatch('_stopLoading', options._stopLoading );
        this.eventDispatcher.dispatch('_fadeOutDim', options._fadeOutDim );

        if (this.cards[this.nowCardIdx]) {
            if (!this.cards[this.nowCardIdx].disappearBackground) return;
            this.cards[this.nowCardIdx].disappearBackground(options._fadeInDimAlpha.delay)
        }

        gsap.delayedCall(options._fadeOutDim.delay, () => {
            this.cards[nextCardIdx].cardOn(options._fadeOutDim.duration);
            this.nowCardIdx = nextCardIdx;
        })
    }
    ////////////////////////////////////////
    stepNextFlower(testMode) {
        this.testMode = testMode;
        

        const nextCardIdx = this.nowCardIdx + 1;

        if (this.nowCardIdx == this.totalCard - 1) return;

        if (nextCardIdx !== this.totalCard - 2) {
            this.eventDispatcher.dispatch('_disableClick', `${nextCardIdx}`);
        }

        if (this.testMode) {
            if (this.nowCardIdx >= 0) {
                if (this.cards[this.nowCardIdx]) {
                    this.cards[this.nowCardIdx].cardOff(0)
                }
            }
        } else {
            if (this.nowCardIdx >= 0 && nextCardIdx < this.totalCard - 1) {
                // [Quest → Loading] : opacity 1
                // [Quset → Quest]   : opacity 0.4
                const alpha = nextCardIdx >= this.totalCard - 2 ? 1 : 0.4;
                
                const callback = () => this.fadeIn_anim(alpha)
                if (this.cards[this.nowCardIdx]) {
                    this.cards[this.nowCardIdx].cardOff(1, callback)
                }
            } else {
                let callback = null;

                if (nextCardIdx == 0) {
                    callback = () => this.eventDispatcher.dispatch('_gameReady', { delay: 0 });
                }
                
                this.fadeIn_anim(1, callback)
            }
        }

        if (this.nowCardIdx == this.totalCard - 2) {
            // [Loading → Result]
            if (this.user.includes('a') || this.user.includes('b')) {
                this.user = this.user;
            } else {
                this.user = this.question.bringResult(this.user)
            }
            
            this.requestResult()
        } else {
            this.updateQuest()
        }
        /////////////// card ready → card change //////////
        this.cards[nextCardIdx].cardReady()
    }

    bringResult() {
        return this.user;
    }
    
    _changeCard(testMode) {
        const nextCardIdx = this.nowCardIdx + 1;

        if (this.testMode) {
            if (nextCardIdx == this.totalCard - 1) {
                //////////////////////////// [Question → Loading]
                const callback = this.stepNextFlower.bind(this)
                this.cards[nextCardIdx].cardOn(0.7, callback);
            } else {
                this.cards[nextCardIdx].cardOn();
            }
            this.nowCardIdx = nextCardIdx;
            return;                
        }
        
        if (nextCardIdx >= 1) {
            if (nextCardIdx == this.totalCard - 1) {
                const onStart = (delay) => {
                    if (this.cards[this.nowCardIdx - 1]) {
                        this.cards[this.nowCardIdx - 1].cardOff(0)
                    }
                    this.cards[nextCardIdx].cardOn(delay)
                }
                this.nowCardIdx = nextCardIdx;
                gsap.delayedCall(this.minimum_delay, () => this.fadeOut_anim(onStart, true))
            } else if (nextCardIdx == this.totalCard - 2) {
                const onStart = (delay) => {
                    if (this.cards[this.nowCardIdx]) {
                        this.cards[this.nowCardIdx].cardOff(0)
                    }
                    const callback = this.stepNextFlower.bind(this)
                    this.cards[nextCardIdx].cardOn(delay, callback)
                    this.nowCardIdx = nextCardIdx;   
                }
                gsap.delayedCall(this.minimum_delay, () => this.fadeOut_anim(onStart))
            } else {
                gsap.delayedCall(this.minimum_delay, () => this.quest_fadeOut_anim())

            }
        } else {
            //////////////////////////// [Home → Intro]

            // None -> Block 먼저 전환, (delay = fade out duration) 완료 후 텍스트 애니메이션 실행
            const onStart = (delay) => {
                this.cards[nextCardIdx].cardOn(delay)
                this.nowCardIdx = nextCardIdx;   
            }
            gsap.delayedCall(this.minimum_delay, () => this.fadeOut_anim(onStart))
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    ////////////////////////////////////////

    updateQuest() {
        if (this.nowCardIdx == -1) return;
        if (this.user.length >= this.maxQuest) return;

        let questValArr;
        let answerValArr;
        if (this.nowCardIdx == 0) {
            questValArr = this.question.questArray[0];
            answerValArr = this.question.answerArray[0];
        } else {
            questValArr = this.question.bringQuest(this.user);
            answerValArr = this.question.bringAnswer(this.user);
        }
        this.cards[this.nowCardIdx + 1].updateQuest(questValArr, answerValArr)
    }
    async requestResult() {

        const result = this.user;
        const is_result = this.is_result;


        await axios
        .post(this.url, 
            { 
                'code': `${result}`,
                'is_result': is_result
            }, 
        )
        .then(res => {
            if (res && res.data) {
                this.cards[this.cards.length - 1].sendResultData(res.data);
            }
        })
        .catch(err => {
            if (err) {
                this.startGame()
            }
        })
    }

    startGame() {
        this.resetGame()

        gsap.set(this.form, { display: 'block', pointerEvents: 'auto' })

        this.stepNextFlower()
    }

    endGame() {
        if (this.nowCardIdx > -1) {
            this.cards[this.nowCardIdx].cardOff()
            gsap.delayedCall(0.5, () => {
                this.resetGame()
            })
        } else {
            this.resetGame()
        }
    }
    resetGame() {
        this.nowCardIdx = -1;
        this.user = '';
        this.cards.forEach(c => c.cardOff(0))
        gsap.set(this.form, { display: 'none', pointerEvents: 'none' })
    }
    resultGame(result) {
        this.is_result = false;
        this.eventDispatcher.dispatch('_gameReady', { delay: 0 });
        this.fadeOut_anim()
        this.nowCardIdx = this.totalCard - 2;
        if (!result) {
            this.startGame()
        } else {
            this.user = String(result)
        }
        gsap.set(this.form, { display: 'block', pointerEvents: 'auto' })

        this.stepNextFlower()
    }
}
