import 'reset-css';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import Flower from './Flower';
import Past from './Past';

import Bridge from './Bridge'
import './style.css';
// import LoadAsset from './LoadAsset';

import Lottie from 'lottie-web';
let testmode = true;


function global_SelectGame(val){
    adobe.selectGame(val)
}

function isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
}

const isMobile = isTouchDevice()

if (isMobile) {
    document.body.classList.add('is-mobile')
}


class EventDispatcher {
    constructor() {
        this.listeners = {};
    }

    addListener(event, callback) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
    }

    removeListener(event, callback) {
        if (this.listeners[event]) {
            this.listeners[event] = this.listeners[event].filter(listener => listener !== callback);
        }
    }

    dispatch(event, data) {
        if (this.listeners[event]) {
            this.listeners[event].forEach(callback => callback(data));
        }
    }
}
function introHeaderAnim() {
    const header = document.querySelector('header');
    const headerElements = gsap.utils.toArray('.el', header);

    gsap.to(headerElements, {
        autoAlpha: 1,
        duration: 1,
        ease: 'power1.out',
        stagger: 0.05,
        onComplete: () => {
            gsap.set(header, { pointerEvents: 'auto' })
            if (document.querySelector('#home')) {
                gsap.to('#home .copyright', { opacity: 1, duration: 1, ease: 'power1.out' })
            }
        }
    })
}
class Adobe {
    constructor() {

        this.games = [];
        this.nowIdx = -1;
        this.dim = document.querySelector('.common-dim')
        this.loading = document.querySelector('.loader');
        this.videos = gsap.utils.toArray('#home video');
        this.createDownloadAnim()

        const eventDispatcher = new EventDispatcher();
        this.eventDispatcher = eventDispatcher;
        this.eventDispatcher.addListener('_restartGame', this.restartGame.bind(this));
        this.eventDispatcher.addListener('_otherGameStart', this.otherStartGame.bind(this));
        
        this.eventDispatcher.addListener('_enableClick', this.enableClick.bind(this));
        this.eventDispatcher.addListener('_disableClick', this.disableClick.bind(this));
        /** Transition - Loading */
        this.eventDispatcher.addListener('_playLoading', this._playLoading.bind(this));
        this.eventDispatcher.addListener('_stopLoading', this._stopLoading.bind(this));
        /** Transition - DIM */
        this.eventDispatcher.addListener('_fadeInDim', this._fadeInDim.bind(this));
        this.eventDispatcher.addListener('_fadeOutDim', this._fadeOutDim.bind(this));
        this.eventDispatcher.addListener('_fadeInDimAlpha', this._fadeInDimAlpha.bind(this));
        
        this.eventDispatcher.addListener('_appearDownloadLayer', this._appearDownloadLayer.bind(this));
        this.eventDispatcher.addListener('_disappearDownloadLayer', this._disappearDownloadLayer.bind(this));
        this.eventDispatcher.addListener('_readyDownloadLayer', this._readyDownloadLayer.bind(this));
        

        this.eventDispatcher.addListener('_gameReset', this._gameReset.bind(this));
        this.eventDispatcher.addListener('_gameReady', this._gameReady.bind(this));

        this.eventDispatcher.addListener('_gameShare', this._gameShare.bind(this));
        this.eventDispatcher.addListener('_gameShareKakao', this._gameShareKakao.bind(this));

        // 동영상 컨트롤
        // this.eventDispatcher.addListener('_suspendVideo', this._suspendVideo.bind(this));

        this._ox = 0;
        this.isCanPlayVideo = true;
        
        this.init()
        gsap.registerPlugin(ScrollTrigger)
        ScrollTrigger.config({ autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load' })


        if (testmode) {
            this.enableClick()
        }

        this._gameShare()
    }
    replaceURL() {

        const url = new URL(window.location.href);
        const urlParams = url.searchParams;
        const type = urlParams.get('type');
        const result = urlParams.get('result');
        const ref = urlParams.get('ref');
        const _id = urlParams.get('id');
        if (type || result) {
            let newURL = url.origin;
            if (ref) {
                newURL = `${url.origin}/?ref=${ref}`
            }

            if (_id) {
                newURL += `&id=${_id}`;
            }
            window.history.pushState('', '', newURL);
        }
    }
    init() {
        this.createLoading()
        introHeaderAnim()
        
        const past = new Past(this.eventDispatcher);
        const flower = new Flower(this.eventDispatcher);

        
        this.games.push(past)
        this.games.push(flower)

        this.selectGameEvent()
        this.selectHomeEvent()
    }
    _gameShare(data) {
        const nowIdx = this.nowIdx;

        if (nowIdx == -1) return;
        const type = nowIdx == 0 ? 'past' : 'flower';
        const nowGame = this.games[nowIdx];
        const _result = nowGame.user;
        let result;

        const { result_title, result_description, result_image } = data;

        if (nowIdx == 1) {
            result = flowerEncode(_result)
        } else {
            result = pastEncode(_result)
        }
        let _result2;
        if (nowIdx == 1) {
            _result2 = flowerDecode(result)
        } else {
            _result2 = pastDecode(result)
        }

        const url = new URL(window.location.href);
        const urlParams = url.searchParams;
        const _id = urlParams.get('id');

        let result_url = `https://aim.plus-ex.com/?type=${type}&result=${result}&ref=1`;
        if (_id) {
            result_url += `&id=${_id}`;
        }
        if (window.navigator) {
            const shareDATA = [
                result_title, 
                result_description,
                result_url
            ]
            if (window.navigator.share) {
                const _text = [shareDATA[0], shareDATA[1]].join('\n');
                navigator.share({
                    title: shareDATA[0],
                    text: _text,
                    url: shareDATA[2]
                });

                const canonical = document.querySelector('link[rel="canonical"]');
                canonical.href = result_url;

            } else {
                if (window.navigator.clipboard) {
                    const shareString = shareDATA.join('\n');
                    window.navigator.clipboard.writeText(shareString).then(() => {
                        alert('링크가 복사되었습니다!')
                    });
                }
            }
        }
    }
    _gameShareKakao(data) {
        const nowIdx = this.nowIdx;

        if (nowIdx == -1) return;
        const type = nowIdx == 0 ? 'past' : 'flower';
        const nowGame = this.games[nowIdx];
        const _result = nowGame.user;
        let result;

        const { result_title, result_description, result_image } = data;

        if (nowIdx == 1) {
            result = flowerEncode(_result)
        } else {
            result = pastEncode(_result)
        }
        let _result2;
        if (nowIdx == 1) {
            _result2 = flowerDecode(result)
        } else {
            _result2 = pastDecode(result)
        }

        const url = new URL(window.location.href);
        const urlParams = url.searchParams;
        const _id = urlParams.get('id');

        let result_url = `https://aim.plus-ex.com/?type=${type}&result=${result}&ref=1`;
        if (_id) {
            result_url += `&id=${_id}`;
        }

        const shareDATA = [
            result_title,
            result_description,
            result_url,
        ]
        try {
            Kakao.Share.sendDefault({
                objectType: 'feed',
                content: {
                    title: result_title,
                    description: result_description,
                    imageUrl: result_image,
                    link: {
                        mobileWebUrl: result_url,
                        webUrl: result_url,
                    },
                },
                buttons: [
                    {
                        title: '친구 결과보고 테스트하기',
                        link: {
                        mobileWebUrl: result_url,
                        webUrl: result_url,
                        },
                    },
                ],
            });
        } catch (error) {
            if (window.navigator) {
                if (window.navigator.share) {
                    const _text = [shareDATA[0], shareDATA[1]].join('\n');
                    navigator.share({
                        title: shareDATA[0],
                        text: _text,
                        url: shareDATA[2]
                    });
    
    
                    const canonical = document.querySelector('link[rel="canonical"]');
                    canonical.href = result_url;
    
                } else {
                    if (window.navigator.clipboard) {
                        const shareString = shareDATA.join('\n');
                        window.navigator.clipboard.writeText(shareString).then(() => {
                            alert('링크가 복사되었습니다!')
                        });
                    }
                }
            }
        }

    }
    _suspendVideo() {
        this.eventDispatcher.dispatch('_suspendVideo')
        this.isCanPlayVideo = false;
        document.body.classList.add('hide-video')
    }
    _playVideo() {
        this.eventDispatcher.dispatch('_playVideo')
        this.isCanPlayVideo = true;
        document.body.classList.remove('hide-video')
    }
    updateFunction(target, idx) {

        const brightness = [this.bx_0, this.bx_1]
        const contrast = [this.gx_0, this.gx_1]
        gsap.set(target, {
            filter: `brightness(${brightness[idx]}) grayscale(${contrast[idx]})`
        })
    }
    updateOpacity() {
        gsap.set(this.dim, { opacity: this._ox })
        this._ox = this._ox;
    }
    /** Transition : intro */

    intro(setAnim) {
        gsap.set('#home', { display: 'block' })
        gsap.utils.toArray('.main-home').forEach((home, idx) => {

            this.bx_0 = 0;
            this.bx_1 = 0;

            this.gx_0 = 1;
            this.gx_1 = 1;
            const background = home.querySelector('.background')
            const video = background.querySelector('video')
            const title = home.querySelector('.text-title')
            const button = home.querySelectorAll('button')
            const buttonBackground = home.querySelectorAll('.button-background');
            const buttonText = home.querySelectorAll('.button-text');
            const subTitle = home.querySelector('.text-sub')
            const subDesc = home.querySelector('.text-desc')
            gsap.set(background, { filter: `brightness(0) grayscale(1)` })
            
            const tl_intro = gsap.timeline({ delay: idx * 0.2 })
            const label = `tl_intro${idx}`;
            const that = this;

            gsap.to('.main-home', { 
                opacity: 1,
                duration: 0, 
                ease: 'power1.inOut',
            })
            
            video.volume = 0;
            video.load()
            
            video.addEventListener('canplaythrough', () => {

                video.play().then(() => {
                    that._playVideo()
                }).catch((e) =>{
                    that._suspendVideo()
                })
            })

            tl_intro.addLabel(label)


            gsap.delayedCall((1 + (0.5 * idx)), () => {
                tl_text.restart()
            })

            if (idx == 0) {
                tl_intro.to(this, {
                    bx_0: 1,
                    gx_0: 0,
                    duration: 2.5,
                    ease: 'power1.inOut',
                    onUpdate: () => this.updateFunction(background, idx),
                }, label)
            } else {
                tl_intro.to(this, {
                    bx_1: 1,
                    gx_1: 0,
                    duration: 2.5,
                    ease: 'power1.inOut',
                    onUpdate: () => this.updateFunction(background, idx),
                }, label)
            }
            tl_intro.addLabel('complete_tl_intro')

            // 로고 + 버튼 동시

            const tl_text = gsap.timeline({ paused: true });
            const label_tl_text = `tl_text${idx}`;
            tl_text.to(title.querySelectorAll('span'), {
                autoAlpha: 1, 
                duration: 1.5,
                ease: 'power1.inOut',
                delay: 0,
                onStart: () => {

                }
            }, label_tl_text)
            tl_text.to(buttonBackground, { 
                scaleX: 1, 
                duration: 0.7,
                ease: 'power2.inOut',
                delay: 0.8
            }, label_tl_text)
            tl_text.to(buttonBackground, { 
                opacity: 1,
                duration: 0.7,
                ease: 'power1.out',
                delay: 0.8,
                onComplete: () => {
                    gsap.set(button, { pointerEvents: 'auto' })
                }
            }, label_tl_text)
            tl_text.to(buttonText, { 
                autoAlpha: 1,
                duration: 1,
                ease: 'power1.out',
                delay: 1.0
            }, label_tl_text)

            tl_text.to(subTitle, { 
                opacity: 1,
                duration: 1,
                ease: 'power1.out',
                delay: 0.5
            }, label_tl_text)
            tl_text.to(subDesc, { 
                opacity: 1,
                duration: 1,
                ease: 'power1.out',
                delay: 0.7
            }, label_tl_text)

            tl_text.addLabel('complete_tl_text')

            if (setAnim) {
                gsap.set('#home', { display: 'none' })
                tl_intro.progress(1).pause();
                tl_text.progress(1).pause();
                return;
            }
        })
    }

    selectGameEvent() {
        gsap.utils.toArray('.button-start').forEach((button, idx) => {
            button.onclick = () => this.startGame(idx)
        })
        // gsap.utils.toArray('.button-result').forEach((button, idx) => {
        //     button.onclick = () => this.resultGame(idx)
        // })
    }
    selectHomeEvent() {
        document.querySelector('.button-home').onclick = () => gsap.delayedCall(0.3, () =>  this.selectHome())
    }
    selectHome() {
        
        this.replaceURL()
        this.eventDispatcher.dispatch('_gameReset')
        if (this.nowIdx == -1) return;
        this.eventDispatcher.dispatch('_disableClick')
        
        gsap.killTweensOf(this, '_ox')
        const options = {
            _fadeInDim: {
                alpha: 1, 
                duration: 1,
                delay: 0
            },
            _fadeOutDim: {
                duration: 1.5,
                delay: 0,
            },
        }


        // this.eventDispatcher.dispatch('_fadeInDim', options._fadeInDi
        this.eventDispatcher.dispatch('_fadeInDimAlpha', options._fadeInDim)
        this.eventDispatcher.dispatch('_fadeInDim', options._fadeInDim)
        const total_delay = options._fadeInDim.duration + options._fadeInDim.delay;
        options._fadeOutDim.delay = total_delay + 0.1;

        gsap.delayedCall(options._fadeOutDim.delay, () => {
            gsap.killTweensOf(this, '_ox')
            this.eventDispatcher.dispatch('_fadeOutDim', { duration:  options._fadeOutDim.duration } )
        })

        gsap.delayedCall(total_delay - 0.1, () => {
            if (this.nowIdx !== -1) {
                var nowGame = this.games[this.nowIdx];
                nowGame.endGame()
                this.nowIdx = -1;
            }
            
            gsap.set('#home', { display: 'block', opacity: 0, onComplete: () => {
                gsap.to('#home', {
                    opacity: 1,
                    duration: 0,
                    ease: 'power1.out',
                    onComplete: () => {
                        this.games.forEach(g => g.resetGame())

                        this.videos.forEach(video => {
                            if (video.paused) {
                                video.play()
                            }
                        })
                        this.eventDispatcher.dispatch('_enableClick')
                    }
                })
  
            } })
            
        })
    }
    createLoading() {
        this.animation = Lottie.loadAnimation({
            container: this.loading, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: 'https://www.plus-ex.com/view_lib/icon-loader-2.json' // the path to the animation json
        });
    }

    /** PG: Home - [시작하기] */
    startGame(val) {
        this.selectGame(val)
    }
    resultGame(val, result) {
        var nowGame = this.games[val];
        this.nowIdx = val;
        nowGame.resultGame(result)
    }
    /** PG: Result - [다시 시작하기] */
    restartGame() {
        this.replaceURL()
        const options = {
            _fadeInDim: {
                alpha: 1, 
                duration: 1,
                delay: 0
            },
            _playLoading: {
                duration: 2,
                delay: 0
            }
        }
        this.eventDispatcher.dispatch('_playLoading', options._playLoading)

        this.eventDispatcher.dispatch('_fadeInDim', options._fadeInDim)
        const total_delay = options._fadeInDim.duration + options._fadeInDim.delay;
        


        gsap.delayedCall(total_delay, () => {
            this.selectGame(this.nowIdx)
        })
    }
    /** PG: Result - [다른 MBTI] */
    otherStartGame() {
        this.eventDispatcher.dispatch('_disableClick')
        this.replaceURL()
        const options = {
            _fadeInDim: {
                alpha: 1, 
                duration: 1,
                delay: 0
            },
            _playLoading: {
                duration: 2,
                delay: 0
            }
        }

        this.eventDispatcher.dispatch('_playLoading', options._playLoading)
        this.eventDispatcher.dispatch('_fadeInDim', options._fadeInDim)
        const total_delay = options._fadeInDim.duration + options._fadeInDim.delay;

        const otherIdx = this.nowIdx == 1 ? 0 : 1;

        gsap.delayedCall(total_delay, () => {
            this.selectGame(otherIdx)
        })
    }
    /** Event : 선택된 테스트 실행 */
    selectGame(val){

        console.log({val})
        const _restart_delay = 1;
        if (history.scrollRestoration) {
            history.scrollRestoration = "manual";
        }
        if(this.nowIdx == -1){
            this.nowIdx = val;
            var nowGame = this.games[this.nowIdx];
            if (!nowGame) {
                gsap.delayedCall(0.2, () => {
                    this.intro()
                })
                return;
            }
            nowGame.startGame();
        }else{
            var nowGame = this.games[this.nowIdx];
            if(this.nowIdx == val) {
                nowGame = this.games[val];
                nowGame.endGame();

            
                this.eventDispatcher.dispatch('_fadeInDim', { alpha: 1, duration: _restart_delay, delay: 0 })
                nowGame.endGame()
                nowGame.resetGame()
                gsap.delayedCall(_restart_delay, () => {
                    var nexIdx = val;
                    var nexGame = this.games[nexIdx];
                    nexGame.startGame();
                    this.nowIdx = nexIdx;
                })
            } else {
                this.eventDispatcher.dispatch('_fadeInDim', { alpha: 1, duration: _restart_delay, delay: 0 })
            
                gsap.delayedCall(_restart_delay, () => {
                    nowGame.endGame()
                    var nexIdx = val;
                    var nexGame = this.games[nexIdx];
                    nexGame.startGame();
        
                    this.nowIdx = nexIdx;
                })

            }
        }

 
    }
    //////////////////////////////////////////////////
    ///////////////////// Global /////////////////////
    ////////////////////////////////////////////////// 
    disableClick(data) {
        document.activeElement.blur()
        gsap.set('.prevent-layer', { display: 'block', backgroundColor: 'blue', zIndex: 3000 })
    }
    enableClick(data) {
        gsap.set('.prevent-layer', { display: 'none' })
    }

    render() {
        // gsap.set(this.dim ,{ opacity: this.dim._ox})
    }
    //////////////////////////////////////////////////
    ///////////////////// NEW /////////////////////
    //////////////////////////////////////////////////
    _gameReady(data) {
        const { delay } = data
        
        gsap.delayedCall(delay, () => {
            gsap.set('#home', { display: 'none', onComplete: () => {
                
                this.videos.forEach(video => {
                    if (!video.paused) {
                        video.pause()
                        video.currentTime = 0;
                    }
                })
            } })
        })
    }
    _gameReset(data) {

    }
    /** alpha, duration, delay */
    _fadeInDimAlpha(data) {
        const { alpha, duration, delay } = data;

        gsap.to(this.dim, {
            backgroundColor: `rgba(0,0,0,${alpha})`,
            duration: duration,
            delay: delay,
            ease: 'power1.inOut',
        })

    }
    _fadeInDim(data) {
        const { alpha, duration, delay } = data;

        gsap.set(this.dim, { display: 'block' })

        if (alpha) {
            gsap.set(this.dim, { backgroundColor: `rgba(0,0,0,${alpha})`})
        }

        gsap.to(this, {
            _ox: 1,
            duration: duration,
            ease: 'power2.out',
            delay: delay,
            onUpdate: () => this.updateOpacity()
        })
    }
    _fadeOutDim(data) {
        const { duration, delay } = data;
        gsap.to(this, {
            _ox: 0,
            duration: duration,
            ease: 'power3.inOut',
            delay: delay,
            onUpdate: () => this.updateOpacity()
        })

    }
    _playLoading(data) {
        const { duration, delay } = data;
        gsap.killTweensOf(this.loading, 'display')
        gsap.set(this.loading, { display: 'block' })

        gsap.to(this.loading, {
            autoAlpha: 1, 
            duration: duration,
            ease: 'power1.inOut',
            delay: delay,
            onStart: () => {
                gsap.delayedCall((duration * 0.1), () => {
                    this.animation.play()
                })
            },
        })
    }
    _stopLoading(data) {
        const { duration, delay } = data;
        gsap.to(this.loading, {
            autoAlpha: 0, 
            duration: duration,
            ease: 'power1.inOut',
            delay: delay,
            onComplete: () => {
                this.animation.stop()
                gsap.set(this.loading, { display: 'none' })
            }
        })
    }

    createDownloadAnim() {
        const loader = document.querySelector('.layer-inapp .loader')
        this._animation = Lottie.loadAnimation({
            container: loader, // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: 'https://www.plus-ex.com/view_lib/icon-loader-2.json' // the path to the animation json
        });
    }

    _appearDownloadLayer(data) {

        const { _callback } = data;
        const layer = document.querySelector('.layer-inapp');
        this.disappearToast(layer.querySelector('.toast'))
        gsap.set(layer, { display: 'block' })
        gsap.to(layer, {
            autoAlpha: 1,
            duration: 0.6,
            ease: 'power3.out',
            delay: 0,
        })
        const loader = layer.querySelector('.loader')

        gsap.killTweensOf(loader, 'display')
        gsap.set(loader, { display: 'block' })

        gsap.to(loader, {
            autoAlpha: 1, 
            duration: 0.4,
            ease: 'power1.inOut',
            delay: 0,
            onStart: () => {
                gsap.delayedCall(0.1, () => {
                    this._animation.play()
                    gsap.delayedCall(0.6, () => {
                        if (_callback) _callback()
                    })
                })
            },
        })
    }
    _readyDownloadLayer(data) {
        const { _callback } = data;
        const layer = document.querySelector('.layer-inapp');
        const loader = layer.querySelector('.loader');
        const toast = layer.querySelector('.toast');

        gsap.to(loader, {
            autoAlpha: 0, 
            duration: 0.4,
            ease: 'power3inOut',
            delay: 0,
            onComplete: () => {
                if (this._animation) {
                    this._animation.stop()
                    gsap.delayedCall(0.1, () => {
                        if (_callback) _callback()
                    })
                }
                gsap.set(loader, { display: 'none' })

                if (document.body.classList.contains('is-mobile')) {
                    gsap.delayedCall(0.1, () => {
                        this.appearToast(toast)
                    })
                }

            }
        })
    }
    _disappearDownloadLayer() {
        const layer = document.querySelector('.layer-inapp');
        const loader = layer.querySelector('.loader')
        const toast = layer.querySelector('.toast')
        
        gsap.to(layer, {
            autoAlpha: 0,
            duration: 0.5,
            ease: 'power3.out',
            delay: 0.3,
        })

        gsap.to(loader, {
            autoAlpha: 0, 
            duration: 0.4,
            ease: 'power3.inOut',
            delay: 0,
            onComplete: () => {
                if (this._animation) {
                    this._animation.stop()
                }
                layer.querySelector('img').remove()
                gsap.set(loader, { display: 'none' })
                gsap.set(layer, { display: 'none' })
                this.disappearToast(toast)
            }
        })
    }
    appearToast(toast, _class) {
        const toastText = toast.querySelector('.toast-text');
        const toastBackground = toast.querySelector('.toast-background');
        const sx = 0.3;

        if (_class) {
            toast.classList.add('is-active')
        }

        gsap.killTweensOf(toast, 'all')
        gsap.killTweensOf(toastBackground, 'all')
        gsap.killTweensOf(toastText, 'all')

        gsap.set(toast, { display: 'none' })
        
        gsap.set(toast, { display: 'block' });
        gsap.from(toastBackground, { 
            scaleX: sx, 
            duration: 0.5,
            ease: 'power2.out',
        });
        gsap.to(toastBackground, {
            opacity: 1, 
            duration: 0.5,
            ease: 'power1.inOut',
        });
        gsap.to(toastText, {
            opacity: 1,
            duration: 0.6,
            ease: 'power1.out',
            delay: 0.3,
            onComplete: () => {
                if (_class) {
                    gsap.delayedCall(3, () => {
                        this.disappearToast(toast, _class)
                    })
                }
            }
        });
    }
    disappearToast(toast, _class) {
        const toastText = toast.querySelector('.toast-text');
        const toastBackground = toast.querySelector('.toast-background');
        const sx = 0.3;
        gsap.set(toast, { display: 'block' });

        gsap.to(toastText, {
            opacity: 0, 
            duration: 0.3,
            ease: 'power1.out',
        });
        gsap.to(toastBackground, {
            opacity: 0, 
            duration: 0.3,
            ease: 'power1.out',
            onComplete: () => {
                gsap.set(toast, { display: 'none' })
                if (_class) {
                    toast.classList.remove('is-active')
                }
            }
        });
    }
    //////////////////////////////////////////////////
    ///////////////////// Global /////////////////////
    ////////////////////////////////////////////////// 
}

let adobe;

var trgEncode = [
    ['a', 'b'],
    ['e', 'f'],
    ['i', 'j'],
    ['m', 'n'],
    ['q', 'r'],
    ['u', 'v'],
]

var trgDecode = {
    'a' : 'a', 'b' : 'b', 
    'e' : 'a', 'f' : 'b', 
    'i' : 'a', 'j' : 'b', 
    'm' : 'a', 'n' : 'b',
    'q' : 'a', 'r' : 'b',
    'u' : 'a', 'v' : 'b'
}

function resizeEvent() {
    if (isMobile) {
        gsap.set(document.body, { '--vh': `${window.innerHeight}px` });
    }
}
window.addEventListener('resize', () => {
    resizeEvent()
})

// 테스트 페이지 접속
function initAdobe () {
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;
    const type = urlParams.get('type');

    // result
    const result = urlParams.get('result');
    const ref = urlParams.get('ref');
    // Type : flower / past
    if (type) {
        adobe = new Adobe();
        window.adobe = adobe;

        if (type == 'flower') {
            if (result) {
                adobe.resultGame(1, flowerDecode(result))
                adobe.intro(true)
            } else {
                adobe.selectGame(1);
                adobe.intro(true)
            }
            adobe.nowIdx = 1;
        } else if (type == 'past') {
            if (result) {
                adobe.resultGame(0, pastDecode(result))
                adobe.intro(true)
            } else {
                adobe.selectGame(0);
                adobe.intro(true)
            }
            adobe.nowIdx = 0;
        } else {
            adobe.intro()
        }
    } else {
        adobe = new Adobe();
        window.adobe = adobe;
        adobe.intro()

        if (!ref) {
            window.history.pushState('', '', url.origin);
        }
    }
}

// 어도비 페이지 접속
function initBrige() {
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;
    const type = urlParams.get('type');

    let view = document.querySelector(`#view-${type}`);
    if (!view) {
        view = document.querySelector('#view-flower');
        window.history.pushState('', '', `${url.origin}/adobe?type=flower`);
    }
    introHeaderAnim()
    new Bridge(view);
    
}
function initDownload() {
    const loader = document.querySelector('.loader');
    gsap.set(loader, { display: 'block' })
    const animation = Lottie.loadAnimation({
        container: loader, // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: 'https://www.plus-ex.com/view_lib/icon-loader-2.json' // the path to the animation json
    });
    gsap.to(loader, {
        autoAlpha: 1, 
        duration: 0.5,
        ease: 'power1.inOut',
        delay: 0,
        onStart: () => {
            gsap.delayedCall(0.1, () => {
                animation.play()
            })
        },
    })
}
function init() {
    gsap.registerPlugin(ScrollTrigger)
    gsap.config({ nullTargetWarn: false, trialWarn: false });
    resizeEvent()

    const url = new URL(window.location.href);

    if (url.pathname.includes('adobe')) {
        initBrige()
    } else {
        initAdobe()
    }

}

window.onload = init()

// 


function flowerEncode(val){
    var rtn = btoa(encode(val))
    return rtn;
}

function flowerDecode(val){
    try {
        decode(window.atob(val))
    } catch(e) {
        return false;
    }
    var rtn = decode(atob(val));
    return rtn;
}

function pastEncode(val){
    var re1 = val.substr(0, 1);
    var re2 = val.substr(1, 5);
    return btoa(re1 + encode(re2))
}

function pastDecode(val){
    try {
        window.atob(val)
    } catch(e) {
        return false;
    }
    var de = atob(val);
    var re1 = de.substr(0, 1);
    var re2 = de.substr(1, 5);
    return re1 + decode(re2)
}


function encode(val){
    var rtn = '';
    for(var i = 0; i < val.length; i++){
        var c = val.substr(i, 1);
        if(c == 'a'){
            rtn += trgEncode[i][0]
        }else{
            rtn += trgEncode[i][1]
        }
    }
    return rtn;
}

function decode(val){
    var rtn = '';
    for(var i = 0; i < val.length; i++){
        var c = val.substr(i, 1);
        rtn += trgDecode[c];
    }
    return rtn;
}





// testEvent()
// function testEvent( ){
//     window.addEventListener('keydown', function (e) {
//         const game = window.adobe.games[window.adobe.nowIdx]
//         if (!game) return;
//         if (e.keyCode == 81) {
//             if (game.nowCardIdx == -1) {
//                 if (window.adobe.nowIdx == 0) {
//                     game.stepNextFlower(true)
//                 } else if (window.adobe.nowIdx == 1) {
//                     game.stepNextPast(true)
//                 }
//             } else {
//                 if (game.nowCardIdx < game.totalCard - 1) {
//                     game._selectAnswer(Math.round(Math.random()), true)
//                 }
//             }

//         }

//         if (e.keyCode == 50) {
//             game.eventDispatcher.dispatch('_otherGameStart', '');
//         }

//         if (e.keyCode == 49) {
//             game.eventDispatcher.dispatch('_restartGame', '');
//         }

//     })
// }
