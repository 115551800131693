export var questDepth_0 = [
    "만나서 반가워요!",
    "지금부터 여러분의 전생을 맞춰볼게요.<br>선택한 시대에 따라<br>서로 다른 전생이 나타납니다.",
]

export var questDepth_1 = [
    "당신의 일상 생활이 궁금해요.",
    "요즘 하루 하루를 보내고 있는<br>당신의 모습은 어떤가요?"
]

export var questDepth_2 = [
    "미리 찾아 본 맛집을 갔더니<br>개인사정으로 문이 닫혀있습니다.",
    "이때 당신의 선택은?"
]

export var questDepth_3 = [
    "퇴근 후에 친구들 모임에 나갔는데,<br>생각보다 모임이 일찍 끝나게 됐습니다.",
    "내 기분은?"
]

export var questDepth_4 = [
    "잠들기 전 최근 지인이나 가족이랑 통화할 때<br>내 모습은?"
]


//////////////////////////////////////////////////
// Answer
//////////////////////////////////////////////////

export var answerDepth_0 = [
    "모래와 신화가 가득한 시대",
    "탐험과 정복의 시대",
    "웅장한 예술의 시대",
    "전쟁과 산업화의 시대"
]

export var answerDepth_1 = [
    "매일 매일 예측 불가다.<br>왜 이렇게 다이나믹한거야",
    "사람 사는게 다 똑같지.<br>루틴에 맞는 하루의 반복이야"
]

export var answerDepth_2 = [
    "어쩔 수 없지 뭐.. 아쉽다.<br>근처에 보이는 식당을 살펴봐야지",
    "왜 문닫은 거야 ㅠㅠ 다른 데 없으면 어쩌지.<br>다른 맛집 검색해봐야겠다"
]

export var answerDepth_3 = [
    "아 뭔가 아쉬운데, 더 놀자고 해볼까?",
    "어? 이렇게 일찍?  ^_____^"
]

export var answerDepth_4 = [
    "소소한 일상들과 함께<br>내가 느꼈던 기분들을 얘기하지",
    "음, 필요한 얘기들..?<br>해야하는 것들이나 있었던 일들을 얘기했어"
]