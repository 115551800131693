import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import CardResult from './CardResult';
import { domToJpeg } from 'modern-screenshot';
import Lottie from 'lottie-web';

export default class PastCardResult extends CardResult {
    constructor(view, idx, eventDispatcher, content, background) {

        super(view, idx, eventDispatcher)


        gsap.registerPlugin(ScrollTrigger)
        ScrollTrigger.config({ autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load' })

        this.view = view;
        this.idx = idx;
        this.eventDispatcher = eventDispatcher;
        this.linkClickEvent = this.openImage.bind(this)

        this.customScrollTrigger = [];
        this.loadData = false;
        this.dataURL = '';
        this.dataContext = '';

        this._window = null;

        // this.inAppBrowser = window.navigator.userAgent.includes('Line');
        this.inAppBrowser = true;

        this.result_title = '';
        this.result_description = '';
        this.result_image = '';
    }


    cardReady() {
        this.dataURL = '';
        if (this.loadData) {
            let isComplete = false;
            let newImg;

            const callback = () => {
                if (isComplete) return;
                isComplete = true;
                if (newImg) {
                    newImg.remove()
                }
                this.eventDispatcher.dispatch('_past_cardReady', 'result');
            }

            if (this.view.querySelector('[data-type=video]') && this.view.querySelector('[data-type=video]').src) {

                if(!document.body.classList.contains('hide-video')) {
                    const video = this.view.querySelector('[data-type=video]');
                    video.load()
                    const videoLoaded = new Promise(function(resolve, reject) {
                        video.addEventListener('canplaythrough', function() {
                            resolve();
                        });
                    });
                    Promise.all([videoLoaded]).then(callback);
                } else {
                    gsap.delayedCall(1, () => callback())
                }
            } else if (this.view.querySelector('[data-type=main_image]') && this.view.querySelector('[data-type=main_image]').src) {
                const image = this.view.querySelector('[data-type=main_image]');
                newImg = new Image()
                newImg.src = image.src;
                const imageLoaded_load = new Promise(function(resolve, reject) {
                    newImg.addEventListener('load', function() {
                        resolve();
                    });
                });
                const imageLoaded_complete = new Promise(function(resolve, reject) {
                    newImg.addEventListener('complete', function() {
                        resolve();
                    });
                });
                Promise.all([imageLoaded_load]).then(callback);
                Promise.all([imageLoaded_complete]).then(callback);
            }
     
        }

    }

    createContentScrollTrigger() {
        const conversation = this.view.querySelector('.result-conversation-wrap');
        if (conversation) {
            if (!conversation.querySelectorAll('.result-conversation-wrap .frame-rect')) return;
            const conversationList = conversation.querySelectorAll('.result-conversation-wrap .frame-rect');
            const quotes = gsap.utils.toArray('.ico-quotes', conversation);
    
            const tl_conversation = gsap.timeline({ paused: true })
            const tl_conversation_label = 'tl_conversation'
    
            tl_conversation.addLabel(tl_conversation_label)
            tl_conversation.to(quotes[0], {
                opacity: 1,
                duration: 1,
                ease: 'power1.out'
            }, tl_conversation_label)
    
    
            gsap.set(conversationList, { y: 30 })
            conversationList.forEach((list, idx) => {
                const text = list.querySelector('.text-inner');
    
                const _stagger = idx * 0.4;
    
    
                tl_conversation.to(list, {
                    autoAlpha: 1,
                    duration: 1,
                    ease: 'power1.out',
                    delay: _stagger,
    
                }, tl_conversation_label)
                tl_conversation.to(list, {
                    y: 0,
                    duration: 1,
                    ease: 'power2.out',
                    delay: _stagger,
                }, tl_conversation_label)
                tl_conversation.to(text, {
                    autoAlpha: 1,
                    duration: 0.8,
                    ease: 'power1.out',
                    delay: _stagger,
                    onStart: () => {
                        if (idx == conversationList.length - 1) {
                            gsap.to(quotes[1], {
                                opacity: 1,
                                duration: 1,
                                ease: 'power1.out',
                                delay: _stagger - 0.2,
                            })
                        }
                    }
                }, tl_conversation_label)
            })


            const _st = ScrollTrigger.create({
                trigger: conversation,
                scroller: this.view,
                start: `20% bottom`,
                end:`bottom top`,
                // invalidateOnRefresh: false,
                // immediateRender: false,
                once: true,
                onEnter: () => {
                    tl_conversation.restart()
                },
            })
            this.customScrollTrigger.push(_st)
        }


        // 콤비
        if (this.view.querySelector('.combi')) {
            gsap.utils.toArray('.combi', this.view).forEach(combi => {
                const text = [combi.querySelector('.text-title'), combi.querySelector('.text-sub')]
                const img = combi.querySelector('img');
    
                const st = ScrollTrigger.create({
                    trigger: combi,
                    scroller: this.view,
                    start: () => `top bottom`,
                    once: true,
                    onEnter: () => {
                        gsap.to(img, {
                            opacity: 1,
                            duration: 1,
                            ease: "power1.out",
                            delay: 0
                        })
                        gsap.to(text, {
                            backgroundPosition: "0% 0%",
                            duration: 1.4,
                            ease: "power2.out",
                            delay: 0
                        })
                    }
                })
    
                this.customScrollTrigger.push(st)       
            })
        }
    }
    sendResultData(res) {
        this.view.querySelector('.result-inner').innerHTML = '';

        const { result_title, result_description, result_image } = res;
        this.result_title = result_title
        this.result_description = result_description
        this.result_image = result_image

        if (res['video']) {
         
            this.view.querySelector(`[data-type=video]`).src = res['video'];
            this.loadData = true;
            this.cardReady()
        }
        const resultContent = this.view.querySelector('.result-inner');

        resultContent.innerHTML = 
        `<div class="result-text">
            ${res['title'] ? `<div class="text-title" data-type="title">${res['title'].split('\n').join('<br />')}</div>` : ''}
            ${res['profile'] ? `<div class="text-profile" data-type="profile">${res['profile'].split('\n').join('<br />')}</div>` : ''}
        </div>
        <div class="result-achievements-wrap">
            <div class="result-achievements">
                ${res['achievements'] ? `<div class="result-achievements-inner"></div>` : ''} 
                ${res['story'] ? 
                    `<div class="result-story frame-rect el">
                        <div class="text-inner" data-type="story">${res['story']}</div>
                    </div>`
                : ''}
                <div class="result-combi el frame-rect" data-type="combi"></div>
            </div>
            ${res['conversation'] 
            ? 
            `<div class="result-conversation-wrap el"><div class="ico-quotes"></div><div class="result-conversation"></div><div class="ico-quotes reverse"></div></div>`
            : 
            ''}
        </div>`
        if (res['achievements']) {
            const achivements = this.view.querySelector('.result-inner .result-achievements-inner');

            for (let i = 0; i < res['achievements'].length; i++) {
                const content = document.createElement('div')
                content.classList.add('frame-rect')
                content.classList.add('el')
                content.setAttribute('data-type', 'achievements');
                achivements.appendChild(content)
            
                let _v = res['achievements'][i];
            
                if (_v.includes('\n')) {
                    _v = _v.split('\n').join('<br />')
                }
            
                content.innerHTML = `<div class="text-inner">${_v}</div>`;
            }
        }

        if (res['conversation']) {
            const conversation = this.view.querySelector('.result-inner .result-conversation');
            for (let i = 0; i < res['conversation'].length; i++) {
                const content = document.createElement('div')
                content.classList.add('frame-rect')
                content.classList.add('el')
                content.setAttribute('data-type', 'conversation');
                conversation.appendChild(content)
            
                let _v = res['conversation'][i];
            
                if (_v.includes('\n')) {
                    _v = _v.split('\n').join('<br />')
                }
            
                content.innerHTML = `<div class="text-inner">${_v}</div>`;
            }
        }

        if (res['positive']) {

            const combi = this.view.querySelector('.result-inner .result-combi')
            res['positive'].map(_val => {
                const { title, thumbnail } = _val;
                const content = document.createElement('div');
            
                content.classList.add('combi')
                content.innerHTML = `
                    <div class="thumbnail">
                        <img src=${thumbnail} alt="">
                    </div>
                    <div class="text">
                    <div class="text-sub">환상의 궁합</div>
                        <div class="text-title">${title}</div>
                </div>`;
            
                combi.appendChild(content)
            })
        }

        if (res['negative']) {
            const combi = this.view.querySelector('.result-inner').querySelector('.result-combi')
            res['negative'].map(_val => {
                const { title, thumbnail } = _val;
                const content = document.createElement('div');
            
                content.classList.add('combi')
                content.innerHTML = `
                    <div class="thumbnail">
                        <img src=${thumbnail} alt="">
                    </div>
                    <div class="text">
                    <div class="text-sub">희대의 상극</div>
                        <div class="text-title">${title}</div>
                </div>`;
            
                combi.appendChild(content)
            })
        }

        
        if (res['main_image']) {
            this.view.querySelector(`[data-type=main_image]`).src = res['main_image'];
        }


        this.initSetStyle()

        this.createScreenshot(res)
    }

    createScreenshot(res) {

        if (document.querySelector('.screenshot')) {
            document.querySelector('.screenshot').remove()
        }
        const result = document.createElement('div');
        result.classList.add('container')
        result.classList.add('result')
        result.classList.add('screenshot')
        result.classList.add('past')

        result.innerHTML = `
        <div class="content">
            <div class="result-image-wrap">
                <span class="frame">
                    <span class="frame-inner"></span>
                    <img src="https://aim.plus-ex.com/images/past/frame.png" alt="">
                </span>
                <div class="image">
                    <span class="image-wrap">
                        <img class="image-frame" src="https://aim.plus-ex.com/images/1aaaa.jpg"  alt="">
                        <img class="result-image" data-type="main_image" src=${res['main_image']} crossOrigin="anonymous" alt="">
                    </span>
                    <span class="logo-text">Powered by Adobe Firefly</span>
                </div>
            </div>
            <div class="content-inner">
                <div class="scroll-element">
                    <div class="image">
                        <span class="image-wrap">
                            <img class="image-frame" src="https://aim.plus-ex.com/images/1aaaa.jpg" alt="">
                        </span>
                    </div>
                </div>
                <div class="result-text">
                    ${res['title'] ? `<div class="text-title" data-type="title">${res['title'].split('\n').join('<br />')}</div>` : ''}
                    ${res['profile'] ? `<div class="text-profile" data-type="profile">${res['profile'].split('\n').join('<br />')}</div>` : ''}
                </div>
                <div class="result-achievements-wrap">
                    <div class="result-inner">
                        <div class="result-achievements-inner"></div>
                    </div>
                </div>
            </div>
        </div>`

        if (res['achievements']) {
            const achivements = result.querySelector('.result-inner .result-achievements-inner');

            for (let i = 0; i < res['achievements'].length; i++) {
                const content = document.createElement('div')
                content.classList.add('frame-rect')
                content.classList.add('el')
                content.setAttribute('data-type', 'achievements');
                achivements.appendChild(content)
            
                let _v = res['achievements'][i];
            
                if (_v.includes('\n')) {
                    _v = _v.split('\n').join('<br />')
                }
            
                content.innerHTML = `<div class="text-inner">${_v}</div>`;
            }
        }

        this.result = result;
        const link = this.view.querySelector('.button-download');
        link.addEventListener('click', this.linkClickEvent)

    }
    createScreenshot_url(init) {
        if (this.dataURL !== '') return;
        const that = this;
        
        document.body.appendChild(this.result);
        document.body.classList.add('is-capture')
        gsap.set(this.result, { opacity: 1 })
     
        let isLoaded = false;
        let _scale = document.body.classList.contains('is-mobile') ? 1.5 : 1.5;
        let _quality = document.body.classList.contains('is-mobile') ? 1 : 0.5;
        const callback = () => {
            if (isLoaded) return;
            isLoaded  = true;
            domToJpeg(this.result, { scale: _scale,
            }).then(dataUrl => {
               that.changeDATA(dataUrl)
               gsap.set(this.result, { opacity: 0 })
               gsap.delayedCall(0.1, () => {
                   if (init) {
                       if (this.inAppBrowser) {
                           this.openResultImage_inapp()
                       } else {
                           if (this._window.document) {
                               this.openResultImage(true)
                           } else {
                               this.inAppBrowser = true;
                               this.openResultImage_inapp()
                           }
                       }
                   }
               })
           })
        }
        const newImg = new Image()
        newImg.crossOrigin = 'anonymous';
        newImg.src = document.querySelector('.screenshot .result-image').getAttribute('src')

        const imageLoaded_load = new Promise(function(resolve, reject) {
            newImg.addEventListener('load', function() {
                resolve();
            });
        });
        const imageLoaded_complete = new Promise(function(resolve, reject) {
            newImg.addEventListener('complete', function() {
                resolve();
            });
        });
        Promise.all([imageLoaded_load]).then(callback);
        Promise.all([imageLoaded_complete]).then(callback);
        
    }

    openImage() {
        if (this.dataURL !== '') {
            if (this.inAppBrowser) {
                const callback = () => this.openResultImage_inapp()
                this.openResultImage_inapp_loading(callback)
            } else {
                this.openResultImage()
            }
        } else {
            if (this.inAppBrowser) {
                this.openResultImage_inapp_loading()
                gsap.delayedCall(1.0, () => {
                    this.createScreenshot_url(true)
                })
            } else {
                this._window = window.open('https://aim.plus-ex.com/download/', '_blank');
                this.createScreenshot_url(true)
            }
            
        }
    }
    changeDATA(rul) {
        this.dataURL = rul;  
    }
    openResultImage(init) {
        if (document.querySelector('.screenshot')) {
            document.querySelector('.screenshot').remove()
        }
        if (!init) {
            this._window = this._window.close()
            this._window = window.open('https://aim.plus-ex.com/download/', '_blank');
        }
        
        setTimeout(() => {
            const image = new Image();
            image.src = this.dataURL;
            image.style.width = '100%';
            this._window.document.write(image.outerHTML);
            gsap.set(this._window.document.body, { backgroundColor: '#000', margin: 0, padding: 0})
        }, 120)
    }
    
    // 라인 인앱 분기처리
    openResultImage_inapp() {        
        if (document.querySelector('.layer-inapp')) {
            const layer = document.querySelector('.layer-inapp')
            const image = document.createElement('img')
            image.crossOrigin = 'anonymous';
            image.src = this.dataURL;
            image.style.width = '100%';
            const callback = () => layer.appendChild(image)
            this.eventDispatcher.dispatch('_readyDownloadLayer', { _callback : callback });
  
            layer.querySelector('.button-close').addEventListener('click', () => {
                this.eventDispatcher.dispatch('_disappearDownloadLayer');
            }, { once: true } )
        } 
    }

    openResultImage_inapp_loading(callback) {
        this.eventDispatcher.dispatch('_appearDownloadLayer', { _callback : callback });
    }
    resetCard(){
        this.loadData = false;
        gsap.set(this.view, { scrollTop: { y: 0 }, onComplete: () => {
            gsap.set(this.view, { display: 'none' })
            this.resetImage()
        } })


        if (this.triggers) {
            this.triggers.forEach((tr, i) => {
                tr.kill()
                if (i == this.triggers.length - 1) {
                    this.triggers = []
                }
            })
        }

        if (this.customScrollTrigger) {
            this.customScrollTrigger.forEach((tr, i) => {
                tr.kill()
                if (i == this.customScrollTrigger.length - 1) {
                    this.customScrollTrigger = []
                }
            })
            
        }
        gsap.killTweensOf(gsap.utils.toArray('.el', this.view))
        gsap.killTweensOf(this.background)
        gsap.killTweensOf(gsap.utils.toArray('.combi'))
        gsap.killTweensOf(gsap.utils.toArray('.combi .text-title'))
        gsap.killTweensOf(gsap.utils.toArray('.combi .text-sub'))
        gsap.set(gsap.utils.toArray('.content-inner', this.view), { clearProps: 'all', opacity: 0, y: this.content_yv })
        gsap.killTweensOf(gsap.utils.toArray('.result-conversation-wrap .frame-rect'), { clearProps: 'all', autoAlpha: 0 })
        gsap.killTweensOf(gsap.utils.toArray('.ico-quotes'), { clearProps: 'all', opacity: 0 })
        gsap.to(gsap.utils.toArray('.el', this.view), { autoAlpha: 0, y: this.yv, duration: 0.1 })
        gsap.utils.toArray('.el', this.view).forEach(e => {
            if (e.hasAttribute('data-load')) {
                e.removeAttribute('data-load')
            }
        })
        gsap.killTweensOf(this.frameWrap.querySelector('.logo-text'))
        gsap.to(this.frameWrap.querySelector('.logo-text'), { clearProps: 'all', opacity: 0, duration: 0.1 })
        
        gsap.killTweensOf(this.view.querySelector('.scroll-detect .ico-arrow'))
        gsap.to(this.view.querySelector('.scroll-detect .ico-arrow'), { clearProps: 'all', opacity: 0, y: -6, duration: 0.1 })
        gsap.killTweensOf(this.view.querySelector('.scroll-detect'))
        gsap.to(this.view.querySelector('.scroll-detect'), { clearProps: 'all', autoAlpha: 1 })

        if (document.querySelector('.screenshot')) {
            document.querySelector('.screenshot').remove()
        }
        document.body.classList.remove('is-capture')
        this.view.querySelector('.button-download').removeAttribute('data-href')
        this.dataURL = '';
        this.result = null;   
        this.view.querySelector('.button-download').removeEventListener('click', this.linkClickEvent)     
      
        if (document.querySelector('.layer-inapp img')) {
            document.querySelector('.layer-inapp img').remove()
        }

        this.result_title = '';
        this.result_description = '';
        this.result_image = '';
    }
}

