import gsap from 'gsap';
import axios from 'axios';

import PastQuest from './PastQuest';

import PastCard from './PastCard';
import PastCardLoading from './PastCardLoading';
import PastCardResult from './PastCardResult';

export default class Past {
    constructor(eventDispatcher) {

        this.form = document.querySelector('#mbti-past');
        this.background = this.form.querySelector('.background-question')

        this.user = '';

        this.question = new PastQuest()
        this.maxQuest = this.question.questArray.length;

        this.cards = [];
        this.totalCard = 0;

        this.nowCardIdx = -1;
        this.eventDispatcher = eventDispatcher;

        this.url = 'https://api.aim.plus-ex.com/api/past';
        this.init()

        this.testMode = false;

        this.minimum_delay = 1;

        this.fadeIn_duration = 1;
        this.fadeOut_duration = 1
        this.play_loading_duration = this.fadeIn_duration * 0.75;
        this.stop_loading_duration = this.fadeOut_duration * 0.75;
        

        this.is_result = true;

        
    }
    init() {
        this.addListenerDispatchEvent()
        this.createCard()
        
        
        
    }

    // DispatchEvent
    addListenerDispatchEvent() {
        this.eventDispatcher.addListener('_past_selectAnswer', this._selectAnswer.bind(this));
        this.eventDispatcher.addListener('_past_cardReady', this._cardReady.bind(this));
        
    }

    _cardReady(data) {
        this._changeCard()
    }
    createCard() {
        const container = this.form.querySelectorAll('.container');
        for (let i = 0; i < container.length - 2; i++) {
            const card = new PastCard(container[i], i, this.eventDispatcher);
            this.cards.push(card)
        }
        const pastCardLoading = new PastCardLoading(container[container.length - 2], container.length - 2, this.eventDispatcher);
        this.cards.push(pastCardLoading)
        const pastCardResult = new PastCardResult(container[container.length - 1], container.length - 1, this.eventDispatcher);
        this.cards.push(pastCardResult)

        this.totalCard = this.cards.length;
    }

    ////////////////////////////////////////
    calcPastThemeBG_value(set) {
        const total = this.background.querySelector('.asset').clientWidth;
        const maxPercentage = ((total - this.form.clientWidth) / this.form.clientWidth) * 100;


        this.backgroundXV = (maxPercentage / (this.maxQuest))

        if (set) {
            if (this.nowCardIdx >= 1)  {
                this.MovePastThemeBG(0)
            }
        }
    }
    selectPastThemeBG() {
        if (this.user.length == 1) {
            window.onresize = () => this.calcPastThemeBG_value(true)
            this.background.querySelector('.asset').src = `https://aim-mbti-image.s3.ap-northeast-2.amazonaws.com/production/past/background/past-theme-${this.user}.jpg`
        }
        gsap.set(this.background, { 
            display: 'block',
            onComplete: () => {
                gsap.delayedCall(0.7, () => {
                    this.calcPastThemeBG_value(true)
                })
            }
        })
    }

    MovePastThemeBG(duration, callback) {
        let idx = this.nowCardIdx == this.total - 2 ? this.nowCardIdx - 1 : this.nowCardIdx

        if (duration == 0) {
            idx = idx - 1;
        }
        
        gsap.to(this.background, { 
            xPercent: `-${this.backgroundXV * idx}`,
            duration: duration,
            ease: 'power2.inOut',
            onComplete: () => {
                if (callback) callback()
            }
        })
    }
    _selectAnswer(answerIdx, testMode) {
        this.testMode = testMode;

        if (this.user.length < 5) {
            this.user += String(answerIdx);
        }
        if (this.nowCardIdx == 0) {
            this.selectPastThemeBG(answerIdx)
        }
        this.stepNextPast()
    }

    fadeIn_anim(alpha, callback) {
        // property

        const options = {
            _fadeInDim: {
                duration: 1,
                delay: 0,
            },
            _playLoading: {
                duration: 1,
                delay: 0
            },
        }

        options._fadeInDim.alpha = alpha ?? 1;

        this.eventDispatcher.dispatch('_fadeInDim', options._fadeInDim )
        this.eventDispatcher.dispatch('_playLoading', options._playLoading )

        if (callback) {
            gsap.delayedCall(options._fadeInDim.duration + options._fadeInDim.delay, () => callback())
        }
    }

    quest_fadeOut_anim(onComplete) {
        const nextCardIdx = this.nowCardIdx + 1;
        const options = {
            _fadeInDimAlpha: {
                alpha: 1,
                duration: 1,
                delay: 0
            },
            _stopLoading: {
                duration: 0.4,
                delay: 0
            },
            _fadeOutDim: {
                duration: 1.5,
                delay: 0,
            }
        }

        options._fadeOutDim.delay += (options._fadeInDimAlpha.duration + options._fadeInDimAlpha.delay)
        // options._fadeOutDim.delay += (options._stopLoading.duration + options._stopLoading.delay)

        this.eventDispatcher.dispatch('_fadeInDimAlpha', options._fadeInDimAlpha );
        this.eventDispatcher.dispatch('_stopLoading', options._stopLoading );
        this.eventDispatcher.dispatch('_fadeOutDim', options._fadeOutDim );

        this.cards[this.nowCardIdx].disappearBackground(options._fadeInDimAlpha.delay)

        gsap.delayedCall(options._fadeOutDim.delay, () => {
            this.cards[nextCardIdx].cardOn(options._fadeOutDim.duration);
            this.nowCardIdx = nextCardIdx;
        })
    }

    fadeOut_anim(onStart, isLast) {

        const options = {
            _stopLoading: {
                duration: 0.7,
                delay: 0
            },
            _fadeOutDim: {
                duration: isLast ? 1 : 1.5,
                delay: 0,
            }
        }

        options._fadeOutDim.delay += options._stopLoading.duration

        this.eventDispatcher.dispatch('_stopLoading', options._stopLoading );
        this.eventDispatcher.dispatch('_fadeOutDim', options._fadeOutDim );

        if (onStart) {
            onStart(options._fadeOutDim.duration)
        }
    }

    stepNextPast(testMode) {
        this.testMode = testMode;

        const nextCardIdx = this.nowCardIdx + 1;
        if (this.nowCardIdx == this.totalCard - 1) return;

        this.eventDispatcher.dispatch('_disableClick', `${nextCardIdx}`);


        if (this.testMode) {
            if (this.cards[this.nowCardIdx]) {
                this.cards[this.nowCardIdx].cardOff(0)
            }
        } else {
            if (this.nowCardIdx >= 0 && nextCardIdx <= this.totalCard - 1) {
                if (nextCardIdx == 1) {
                    const callback = () => this.fadeIn_anim()
                    this.cards[this.nowCardIdx].cardOff(1, callback)
                } else if (nextCardIdx == this.totalCard - 1) {
                    const callback = () => this.fadeIn_anim()
                    this.cards[this.nowCardIdx].cardOff(1, callback)
                } else {
                    this.cards[this.nowCardIdx].cardOff(1)
                }
            } else {
                // Intro → Quest : 테마 선택 값으로 이미지 세팅
                let callback = null;

                if (nextCardIdx == 0) {
                    callback = () => this.eventDispatcher.dispatch('_gameReady', { delay: 0 });
                }
                this.fadeIn_anim(1, callback)
            }
        }

        if (this.nowCardIdx == this.totalCard - 2) {
            if (this.user.includes('a') || this.user.includes('b')) {
                this.user = this.user;
            } else {
                this.user = this.question.bringResult(this.user)    
            }
            
            this.requestResult()
        } else {
            this.updateQuest()
        }
        this.cards[nextCardIdx].cardReady()
    }

    _changeCard(testMode) {
        const nextCardIdx = this.nowCardIdx + 1;

        if (nextCardIdx >= this.totalCard) return;

        if (this.testMode) {
            if (nextCardIdx == this.totalCard - 2) {
                //////////////////////////// [Question → Loading]
                const callback = this.stepNextFlower.bind(this)
                this.cards[nextCardIdx].cardOn(0.7, callback);
            } else {
                this.cards[nextCardIdx].cardOn();
            }
            this.nowCardIdx = nextCardIdx;
            return;                
        }
        if (nextCardIdx >= 1) {
            if (nextCardIdx == 1) {
                const onStart = (delay) => {
                    this.cards[0].resetCard()
                    this.cards[nextCardIdx].cardOn(delay + 0.4)
                    this.nowCardIdx = nextCardIdx;
                }
                gsap.delayedCall(this.minimum_delay, () => this.fadeOut_anim(onStart))
            } else if (nextCardIdx == this.totalCard - 1) {
                const onStart = (delay) => {
                    gsap.set(this.background, { display: 'none' })
                    if (this.cards[this.nowCardIdx]) {
                        this.cards[this.nowCardIdx].cardOff(0)
                    }
                    this.cards[nextCardIdx].cardOn(delay)
                    this.nowCardIdx = nextCardIdx;
                }
                gsap.delayedCall(this.minimum_delay, () => this.fadeOut_anim(onStart, true))

            } else if (nextCardIdx == this.totalCard - 2) {
                const moveBG_duration = 1.5;
                this.MovePastThemeBG(moveBG_duration)
                const callback = this.stepNextPast.bind(this)
                if (this.cards[nextCardIdx]) {
                    this.cards[nextCardIdx].cardOn(moveBG_duration - 0.2, callback)
                }
                this.nowCardIdx = nextCardIdx;

            } else {
                // Fade

                gsap.delayedCall(0.5, () => {
                    const moveBG_duration = 1.5;
                    this.MovePastThemeBG(moveBG_duration)
                    if (this.cards[nextCardIdx]) {
                        this.cards[nextCardIdx].cardOn(moveBG_duration - 0.2)
                    }
                    gsap.delayedCall(moveBG_duration * 1.5, () => {
                        this.cards[nextCardIdx - 1].resetCard()
                    })
                    this.nowCardIdx = nextCardIdx;
                })
   
                // gsap.delayedCall(this.minimum_delay, () => this.quest_fadeOut_anim())
            }
        } else {
            
            const onStart = (delay) => {
                this.cards[nextCardIdx].cardOn(delay)
                this.nowCardIdx = nextCardIdx;   
            }
            gsap.delayedCall(this.minimum_delay, () => this.fadeOut_anim(onStart))
        }
    }

    updateQuest() {
        let questValArr = this.question.bringQuest(this.user);
        let answerValArr = this.question.bringAnswer(this.user);
        if (this.nowCardIdx == -1) {
            questValArr = this.question.questArray[this.nowCardIdx + 1]
            answerValArr = this.question.answerArray[this.nowCardIdx + 1]
        }
        if (!questValArr) return;
        this.cards[this.nowCardIdx + 1].updateQuest(questValArr, answerValArr)
    }

    
    async requestResult() {
        const result = this.user;
        const is_result = this.is_result;

        await axios
        .post(this.url, 
            { 
                'code': `${result}`,
                'is_result': is_result
            }, 
        )
        .then(res => {
            this.cards[this.totalCard - 1].sendResultData(res.data);
        })
        .catch(err => {
            if (err) {
                this.startGame()
                
            }
        })
    }

    startGame() {
        this.resetGame()
        

        gsap.set(this.form, { display: 'block', pointerEvents: 'auto' })

        this.stepNextPast()

        
    }
    endGame() {
        if (this.nowCardIdx > -1) {
            this.cards[this.nowCardIdx].cardOff()
            gsap.delayedCall(0.5, () => {
                this.resetGame()
            })
        } else {
            this.resetGame()
        }
    }
    resetGame() {
        this.nowCardIdx = -1;
        this.user = '';
        this.cards.forEach(c => c.cardOff(0))
        gsap.set(this.background, { xPercent: 0 })
        // this.changeBackgroundTransition(0)
        gsap.set(this.form, { display: 'none', pointerEvents: 'none' })
        
        window.onresize = null;
    }
    resultGame(result) {
        this.is_result = false;
        this.eventDispatcher.dispatch('_gameReady', { delay: 0 });
        // this.eventDispatcher.dispatch('_stopLoading', { duration: 0.5, delay: 0 } );
        // this.eventDispatcher.dispatch('_fadeOutDim', { duration: 0.5, delay: 0 } );
        this.nowCardIdx = this.totalCard - 2;

        if (!result) {
            this.startGame()
            // for (let i = 0; i < 5; i++) {
            //     this.user += Math.round(Math.random())
            // }
            // this.user = this.question.bringResult(this.user)
        } else {
            this.user = result
        }

        gsap.set(this.form, { display: 'block', pointerEvents: 'auto' })

        this.stepNextPast()
    }
}
