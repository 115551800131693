import gsap from 'gsap';
import Card from './Card';


export default class FlowerCard extends Card {
    constructor(view, idx, eventDispatcher) {
        super(view, idx, eventDispatcher)
        this.view = view;

        this.idx = idx;
        this.eventDispatcher = eventDispatcher;
    
        this.background = this.view.querySelector('.asset');

        this.backgroundDim = this.view.querySelector('.dim')

        this.questText = this.view.querySelectorAll('.question-container .question');
        this.answerText = this.view.querySelectorAll('.answer-container .button-answer');

        this.visibleQuestText = this.questText;
        this.visibleAnswerText = this.answerText;
        
        this.isCanPlayVideo = true;

        this._playbackRate = 1;
        this.createSelectAnswer()
    }

    addListenerDispatchEvent() {
        this.eventDispatcher.addListener('_suspendVideo', this._suspendVideo.bind(this));
        this.eventDispatcher.addListener('_playVideo', this._playVideo.bind(this));
    }

    /** Event 'Click' : 답변 클릭 시 발생할 이벤트 실행 */
    createSelectAnswer() {
        this.answerText.forEach((answer, answerIdx) => {
            answer.onclick = () => {
                // if (document.body.classList.contains('is-mobile')) {
                //     if (answer.classList.contains('is-click')) {
                //         answer.classList.add('is-click')
                //         gsap.delayedCall(0.25, () => {
                //             answer.classList.remove('is-click')
                //         })
                //     }
                // }
     
                this._selectAnswer(answerIdx)
            }
        })
    }
    _selectAnswer(answerIdx) {
        this.eventDispatcher.dispatch('_flower_selectAnswer', answerIdx);
    }
    updateQuest(questValArr, answerValArr) {

        this.visibleQuestText = [];
        this.questText.forEach((_title, _index) => {
            if (questValArr.length - 1 < _index) {
                gsap.set(_title, { display: 'none' })
                _title.querySelector('.quest-text').innerHTML = '';
            } else {
                gsap.set(_title, { display: 'block' })
                _title.querySelector('.quest-text').innerHTML = questValArr[_index];
                this.visibleQuestText.push(_title)
            }
        })

        this.visibleAnswerText = [];
        this.answerText.forEach((_title, _index) => {
            gsap.set(_title.querySelector('.button-background'), { scaleX: this.sx, opacity: 0 })
            gsap.set(_title.querySelector('.answer-text'), { opacity: 0 })
            if (answerValArr.length - 1 < _index) {
                gsap.set(_title, { display: 'none' })
                _title.querySelector('.answer-text').innerHTML = '';
            } else {
                gsap.set(_title, { display: 'block' })
                _title.querySelector('.answer-text').innerHTML = answerValArr[_index]
                this.visibleAnswerText.push(_title)
            }
        })
    }
    /** 답변에 따른 이미지 변경 */
    setAttributeAsset(src) {
        this.background.src = src;
    }
    cardReady() {
        

        let isComplete = false;
        let newImg;

        const callback = () => {
            if (isComplete) return;
            isComplete = true;
            if (newImg) {
                newImg.remove()
            }
            this.eventDispatcher.dispatch('_flower_cardReady', this.idx);
        }
        
        if (this.background) {
            if (this.background.tagName == 'VIDEO') {
                if(!document.body.classList.contains('hide-video')) {
                    const video = this.background;
                    video.load()
                    const videoLoaded = new Promise(function(resolve, reject) {
                        video.addEventListener('canplaythrough', function() {
                            resolve();
                        });
                    });
                    Promise.all([videoLoaded]).then(callback);
                } else {
                    gsap.delayedCall(2, () => callback())
                }
            } else {

                newImg = new Image()
                newImg.src = this.background.src;
                const imageLoaded_load = new Promise(function(resolve, reject) {
                    
                    newImg.addEventListener('load', function() {
                        resolve();
                    });
                });
                const imageLoaded_complete = new Promise(function(resolve, reject) {
                    newImg.addEventListener('complete', function() {
                        resolve();
                        
                    });
                });
                Promise.all([imageLoaded_load]).then(callback);
                Promise.all([imageLoaded_complete]).then(callback);
            }
        } else {
            callback()
        }
    }

    //////////////////////////////////////////////////////////////
    /////////////////////////// Card State ///////////////////////
    //////////////////////////////////////////////////////////////

    cardOn(delay) {
        gsap.set(this.view, {
            display: 'block',
            pointerEvents: 'auto',
            onComplete: () => {
                this.appearBackground(delay)
            }
        })
    }
    cardOff(val, callback) {
        gsap.killTweensOf(this.view, 'display')
        gsap.set(this.view, { pointerEvents: 'none' })

        if (val == 0) {
            this.resetCard()
        } else {
            this.disappearQuestText()
            this.disappearAnswerText(callback)
        }
    }

    //////////////////////////////////////////////////////////////
    /////////////////////////// Transition ///////////////////////
    //////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////////////
    /** @desc delay : 비디오인 경우 첫 프레임이 블랙이라 끊겨보이는 이슈가 있어 추가 */
    appearBackground(delay) {
        const that = this;
        gsap.set(this.background, {
            autoAlpha: 1,
            onComplete: () => {

                let _time = delay - 0.6;

                if (this.idx == 0) {
                    _time = delay + 0;
                }
                gsap.delayedCall(_time, () => {
                    this.appearQuestText()
                })
            }
        })
        gsap.from(this.background, {
            xPercent: 2,
            duration: 1.8,
            ease: 'power2.out',
            onUpdate: function() {},
            onStart: () => {
                if (this.background.tagName == 'VIDEO') {
                    if (this.background.paused) {
                        this.background.play().then(() => {
                            that._playVideo()
                        }).catch((e) =>{
                            that._suspendVideo()
                        })
                    }
                }
            }
        })
    }

    // updateFunction() {
    //     this.background.playbackRate = this._playbackRate.toFixed(1)
    // }
    
    disappearBackground(delay, callback) {
        gsap.to(this.background, {
            xPercent: -2,
            duration: 1.6,
            ease: 'power1.out',
            delay: delay,

            onComplete: () => {
                if (this.background.tagName == 'VIDEO') {
                    this.background.currentTime = 0;
                }
            }
        })
        gsap.to(this.background, {
            autoAlpha: 0,
            duration: 1,
            ease: 'power1.inOut',
            delay: delay,
            onStart: () => {
                if (this.background.tagName == 'VIDEO') {
                    if (!this.background.paused) {
                        gsap.to(this, { 
                            _playbackRate: 0,
                            duration: 1,
                            // onUpdate: () => this.updateFunction(),
                            onComplete: () => {
                                if (!this.background.paused) {
                                    this.background.pause()
                                }
                                this.background.playbackRate = 1.0;
                            }
                        })
                    }
                }
            },
            onComplete: () => {
                if (callback) callback()
                this.resetCard()
            }
        })

    }
    resetCard(){
        
        gsap.set(this.view, { display: 'none' })

        gsap.killTweensOf(this.questText)
        gsap.killTweensOf(this.answerText)

        this.killChildTweensOf(this.questText)
        this.killChildTweensOf(this.answerText)

        gsap.killTweensOf(gsap.utils.toArray('.answer-container .button-answer', this.view), 'all')
        gsap.killTweensOf(gsap.utils.toArray('.answer-container .button-background', this.view), 'all')
        gsap.killTweensOf(gsap.utils.toArray('.answer-container .answer-text', this.view), 'all')

        gsap.killTweensOf(gsap.utils.toArray('.question-container .question', this.view), 'all')
        gsap.killTweensOf(gsap.utils.toArray('.question-container .question .quest-background', this.view), 'all')
        gsap.killTweensOf(gsap.utils.toArray('.question-container .question .quest-text', this.view), 'all')

        gsap.set(gsap.utils.toArray('.question-container .question', this.view), { clearProps: 'all', display: 'none' })
        gsap.set(gsap.utils.toArray('.question-container .question .quest-background', this.view), { clearProps: 'all', autoAlpha: 0 })
        gsap.set(gsap.utils.toArray('.question-container .question .quest-text', this.view), { clearProps: 'all', autoAlpha: 0 })
        gsap.set(gsap.utils.toArray('.answer-container .button-answer', this.view), { clearProps: 'all', display: 'none' })
        gsap.set(gsap.utils.toArray('.button-background', this.view), { clearProps: 'all', scaleX: this.sx, opacity: 0 })
        gsap.set(gsap.utils.toArray('.answer-text', this.view), { clearProps: 'all', opacity: 0 })
        

        if (this.background && this.background.tagName == 'VIDEO') {
            if(!this.background.paused) {
                this.background.pause()
            }
            this.background.currentTime = 0;
        }
    }
    killChildTweensOf(ancestor) {
        ancestor = gsap.utils.toArray(ancestor)[0];
        gsap.globalTimeline
          .getChildren(true, true, false)
          .forEach((tween) =>
            tween
            .targets()
            .forEach((e) => e.nodeType && ancestor.contains(e) && tween.kill(e))
          );
    }
}