import { 
    questDepth_0, questDepth_1, questDepth_2, questDepth_3, questDepth_4, 
    answerDepth_0, answerDepth_1, answerDepth_2, answerDepth_3, answerDepth_4
} from './DATA/DATA_Past';


export default class PastQuest {
    constructor() {
        // DATA(Text): Array
        this.questArray = [];
        this.answerArray = [];
        this.resultArray = [
            ['a','b','c','d'],
            ['p', 'j'],
            ['n', 's'],
            ['e', 'i'],
            ['f', 't']
        ];

        this.init()
    }


    init() {
        this.initData()
    }

    initData() {
        this.questArray = [questDepth_0, questDepth_1, questDepth_2, questDepth_3, questDepth_4,]
        this.answerArray = [answerDepth_0, answerDepth_1, answerDepth_2, answerDepth_3, answerDepth_4]
    }
    
    //////////////////////////////////////////////////
    // Quest
    //////////////////////////////////////////////////

    bringQuest(val){
        var quest = this.questArray[val.length];
        return quest;
    }

    bringAnswer(val){
        var answer = this.answerArray[val.length];
        return answer;
    }
    //////////////////////////////////////////////////
    // Result
    //////////////////////////////////////////////////
    bringResult(val){
        var rtn = '';
        for(var i = 0; i < val.length; i++){
            var idx = parseInt(val.substr(i , 1)) + 97;
            if (i == 0) {
                rtn += parseInt(val.slice(0, 1)) + 1;
            } else {
                rtn += String.fromCharCode(idx);
            }
            
        }
        return rtn;
    }
    // bringResult(val){
    //     var q1 = this.resultArray[0][parseInt(val.substr(0, 1))];
    //     var q2 = this.resultArray[1][parseInt(val.substr(1, 1))];
    //     var q3 = this.resultArray[2][parseInt(val.substr(2, 1))];
    //     var q4 = this.resultArray[3][parseInt(val.substr(3, 1))];
    //     var q5 = this.resultArray[4][parseInt(val.substr(4, 1))];
    //     return q1 + q4 + q3 + q5 + q2;
    // }
}