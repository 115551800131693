import gsap from 'gsap';
import SplitText from 'gsap/SplitText';

export default class Card {
    constructor(view, idx, eventDispatcher) {

        gsap.registerPlugin(SplitText)
        this.view = view;
        this.idx = idx;
        this.eventDispatcher = eventDispatcher;
        
        this.questText = this.view.querySelectorAll('.question-container .question');
        this.answerText = this.view.querySelectorAll('.answer-container .button-answer');

        this.visibleQuestText = this.questText;
        this.visibleAnswerText = this.answerText;



        this.addListenerDispatchEvent()
        // this.splitLines = [];
    }
    addListenerDispatchEvent() {}

    _suspendVideo() {
        this.isCanPlayVideo = false;
        if(!document.body.classList.contains('hide-video')) {
            document.body.classList.add('hide-video')
        }
    }
    _playVideo() {
        this.isCanPlayVideo = true;
        if (document.body.classList.contains('hide-video')) {
            document.body.classList.remove('hide-video')
        }
    }

    cardOn() {
        gsap.set(this.view, {
            display: 'block',
            pointerEvents: 'auto',
            onComplete: () => {
                gsap.delayedCall(delay, () => {
                    this.appearBackground()
                })
            }
        })
    }
    cardOff(val) {
        gsap.killTweensOf(this.view, val, 'display')
        gsap.set(this.view, { pointerEvents: 'none' })

        if (val == 0) {
            this.resetCard()
        } else {
            this.disappearQuestText()
            this.disappearAnswerText()
        }
    }



    //////////////////////////////////////////////////////////////
    /////////////////////////// Appear ///////////////////////////
    //////////////////////////////////////////////////////////////

    /** Quest Text 등장 */
    appearQuestText(callback) {

        this.visibleQuestText.forEach((question, idx) => {
            const questionBG = question.querySelector('.quest-background')
            const _stagger = idx * 0.3;

            gsap.set(question, { display: 'block', y: 50 })
       

            gsap.to(questionBG, {
                autoAlpha: 1,
                duration: 0.7,
                ease: 'power1.inOut',
                delay: _stagger 
            })
            gsap.to(question.querySelector('.quest-text'), {
                autoAlpha: 1,
                duration: 0.35,
                ease: 'power2.inOut',
                delay: _stagger + 0.2
            })
            gsap.to(question, {
                y: 0,
                duration: 0.8,
                ease: 'power2.out',
                delay: _stagger,
                onComplete: () => {
                    if (idx == this.visibleQuestText.length - 1) {
                        gsap.delayedCall(0.01, () => {
                            this.appearAnswerText()
                            if (callback) callback()
                        })
                        
                    }
                }
            })
        })
    }

    /** Answer Button 등장 */
    appearAnswerText(callback) {
        gsap.set(this.visibleAnswerText, { display: 'block' })
        this.sx = 0.3;

        this.visibleAnswerText.forEach((answer, idx) => {
            const _stagger = idx * 0.25;

            // Background : scaleX
            
            gsap.set(answer.querySelector('.button-background'), { scaleX: this.sx })
            
            gsap.to(answer.querySelector('.button-background'), { 
                scaleX: 1, 
                duration: 0.5,
                ease: 'power1.out',
                delay: _stagger 
            })
            // Background : opacity
            gsap.to(answer.querySelector('.button-background'), { 
                opacity: 1, 
                duration: 0.7,
                ease: 'power1.inOut',
                delay: _stagger 
            })
            // Background : text
            gsap.to(answer.querySelector('.answer-text'), { 
                opacity: 1,
                duration: 0.6,
                ease: 'power1.out',
                delay: _stagger + 0.4,
                onComplete: () => {
                    if (idx == this.visibleAnswerText.length - 1) {
                        this.eventDispatcher.dispatch('_enableClick', '')
                        if (callback) callback()
                    }
                }
            })
        })
    }

    //////////////////////////////////////////////////////////////
    /////////////////////////// DisAppear ////////////////////////
    //////////////////////////////////////////////////////////////

    /** Quest Text 사라짐 */
    disappearQuestText() {
        gsap.killTweensOf(this.questText, '*')
        if (!this.visibleQuestText) return;
        this.visibleQuestText.forEach((question, idx) => {
            const _stagger = idx * 0;
            gsap.to(question.querySelector('.quest-text'), {
                opacity: 0,
                duration: 0.3,
                ease: 'power1.out',
                stagger: _stagger,
                onComplete: () => {
                    // this.resetCard()
                
                }
            })
            gsap.to(question, {
                autoAlpha: 0,
                duration: 0.3,
                ease: 'power1.out',
                stagger: _stagger,
                onComplete: () => {
                    // if (this.splitLines[idx]) {
                    //     this.splitLines[idx].revert()
                    // }
                    // if (idx == this.visibleQuestText.length - 1) {
                    //     this.splitLines = [];
                    // }
                }
            })
        })
    }
    /** Answer Button 사라짐 */
    disappearAnswerText(callback) {
        gsap.killTweensOf(this.answerText, '*')

        this.visibleAnswerText.forEach((answer, idx) => {
            const _stagger = idx * 0;
            const _text_delay = 0.3;
     
            // Background : text
            gsap.to(answer.querySelector('.answer-text'), { 
                opacity: 0,
                duration: 0.3,
                ease: 'power1.out',
                delay: _stagger,
                onComplete: () => {
                    if (callback) {
                        callback()
                    }
                }
            })
                        
            // Background : opacity
            gsap.to(answer.querySelector('.button-background'), { 
                opacity: 0, 
                duration: 0.3,
                ease: 'power1.out',
                delay: _stagger
            })

        })
    }

}