import gsap from 'gsap';
// import axios from 'axios';

import { 
    questDepth_0, questDepth_1, questDepth_2, questDepth_3, questDepth_4, questDepth_5,
    answerDepth_0, answerDepth_1, answerDepth_2, answerDepth_3, answerDepth_4, answerDepth_5
} from './DATA/DATA_Flower';


export default class FlowerQuest {
    constructor() {
        // DATA(Text): Array
        this.questArray = [];
        this.answerArray = [];

        this.init()
    }


    init() {
        this.initData()
    }

    initData() {
        this.questArray = [questDepth_0, questDepth_1, questDepth_2, questDepth_3, questDepth_4, questDepth_5]
        this.answerArray = [answerDepth_0, answerDepth_1, answerDepth_2, answerDepth_3, answerDepth_4, answerDepth_5]
    }
    
    //////////////////////////////////////////////////
    // Quest
    //////////////////////////////////////////////////

    bringQuest(val){
     
        var rtn = 0;
        for(var i = 0; i < val.length; i++){
            var trg = val.substr(i, 1);
            var stepVal = Math.pow(2, (val.length - 1 - i));
            rtn += parseInt(trg) * stepVal;
        }
        var quest = this.questArray[val.length][rtn];
        return quest;
    }

    bringAnswer(val){
       
        var rtn = 0;
        for(var i = 0; i < val.length; i++){
            var trg = val.substr(i, 1);
            var stepVal = Math.pow(2, (val.length - 1 - i));
            rtn += parseInt(trg) * stepVal;
        }
        var answer = this.answerArray[val.length][rtn];
        return answer;
    }
    //////////////////////////////////////////////////
    // Result
    //////////////////////////////////////////////////
    bringResult(val){
        var rtn = '';
        for(var i = 0; i < val.length; i++){
            var idx = parseInt(val.substr(i , 1)) + 97;
            rtn += String.fromCharCode(idx);
        }
        return rtn;
    }

    // bringResult2(val){
    //     var q1 = this.resultArray[0][parseInt(val.substr(0, 1))];
    //     var q2 = this.resultArray[1][parseInt(val.substr(1, 1))];
    //     var q3 = this.resultArray[2][parseInt(val.substr(2, 1))];
    //     var q4 = this.resultArray[3][parseInt(val.substr(3, 1))];
    //     var q5 = this.resultArray[4][parseInt(val.substr(4, 1))];
    //     return q1 + q4 + q3 + q5 + q2;
    // }
}