export var questDepth_0 = [
    "끝없이 펼쳐진 황무지를 걷는 중...",
    "왼쪽 길은 누군가 지나간 흔적이 보이고, \n오른쪽 길은 아무런 흔적 없이 조용해 보입니다.",
    "어느 방향으로 출발할까요?"
]

export var questDepth_1 = [
    ["흔적을 쫓던 중 낡은 지도 한 장을 발견했습니다.","지도 한구석에는 '위험 지역'이라 적혀 있지만, \n그곳 자원이 풍부하다는 것을 암시하는 표시도 \n함께 보입니다.","자원을 위해 '위험 지역'의 길을 탐험할까요?"],
    ["길을 따라 온 곳은 위협이 적지만,\n씨앗을 키우기에는 자원이 부족해보입니다.\n자원을 찾아나서기로 한 당신","앗…. 그런데 20km 앞에서부터\n강한 모래바람이 몰아치기 시작했습니다","당신과 씨앗의 생존을 위해 \n어떻게 하는게 좋을까요?"]   
]

export var questDepth_2 = [
    ["다행히 지도가 틀리지 않았습니다.\n무사히 자원을 찾을 셨군요.","갑자기 먼지 구름과 쿵쿵 발자국 소리가,,,\n약탈자들이 다가오고 있는 것 같아요."],
    ["새로 도착한 이곳의 땅은 \n정말 푸르고 비옥해 보여요!\n씨앗을 키우기 좋은 장소인 것 같아요.","하지만 조심하셔야해요. 이곳은 흉포한 \n야생동물의 영역이기도 해요.","나뭇잎 사이 발자국.. 멀리서 들리는 짐승 울음소리..\n그들의 위협적인 기운이 주위를 맴돌고 있어요."],
    ["Firefly의 빛을 따라 \n동굴 속 복잡한 길을 걸어가던 중 \n특별해 보이는 광석을 발견한 당신,","엇.. 그런데 이 소리는…?\n\n근처에 거주하는 유목민 소리네요","그들은 당신이 발견한 광석을 노릴 거예요\n협상하거나 지금 도망가는 게 좋겠어요."],
    ["거친 모래바람 속 Firefly의 빛을 따라가던 중 \n특별한 신호를 발견한 당신,","이는 근처 도시 생존자가 보내는 신호로 보입니다.\n이 신호를 따라갈 지 결정해야 합니다."]
]

export var questDepth_3 = [
    ["휴.. 정말 지독한 싸움이었습니다.\n그래도 자원을 얻어내서 다행이에요","엇, 근처에 새로운 에너지 신호가 잡히고 있어요.\n이 에너지가 어쩌면 씨앗에 도움이 될 것 같은데..","이곳을 조금 더 연구해 보는 건 어떨까요?"],
    ["약탈자와의 정보 교환은 성공적이네요\n이곳에 대한 정보를 꽤 얻었어요","그의 정보에 따르면 도시 외곽에는\n 씨앗을 연구할 수 있는 연구소가 있고,","중심부에는 생존자들이 모여있어, \n이미 꽤 안전한 환경이 조성되어 있는 것 같아요."],
    ["휴, 다행히 짐승 한 마리 얼씬거리지 않고, \n무사히 밤을 보낼 수 있었어요.","엇, GPS에 이 부근 생존자\n마을이 확인되고 있어요. 그런데\n아까부터 들리는 이 소리는 뭐죠?","졸졸졸졸.."],
    ["정말 위험했어요,.\n적어도 이제 당신을 공격하지 않아 다행이에요.\n이제 안심하고 오아시스로 떠나볼까요?","어? 저 유적지는 뭐죠?\n저곳은 지도에도 나와 있지 않은 곳이에요","흠... 오아시스로 향할 준비를 해야 할까요, \n지도에 없는 이 유적지를 좀 더 탐험해 볼까요?"],
    ["협상을 하기로 한 당신은\n그들에게 광석을 내어주고","유목민은 당신에게 오아시스\n위치를 알려주기로 했습니다.","추가로 그들은 당신에게 오아시스 반대편\n미지의 장소에 대한 정보도 제공합니다."],
    ["광석을 챙긴 당신은 사막 유목민에게 쫓겼지만\n\nFirefly의 빛의 도움으로\n복잡한 동굴 터널 속을 무사히 탈출했습니다.","밖을 나오자 근처에는 마을이 보이고\n그 반대편에는 물소리와 신비한 신호가 \n감지되는 숲이 펼쳐져 있습니다.","이제 어디로 향할까요?"],
    ["특별한 신호를 따라간 당신은 \n황폐한 도시에서 생존자 그룹과 조우합니다.","조금씩 지치기 시작한 당신과 씨앗 \n이제 씨앗도 생명력이 얼마 남지 않은 것 같은데..","오래된 식물학 연구소로 향할지\n아니면, 이곳에 남아 생존자들의 \n도움을 받을지 선택해야합니다."],
    ["모래바람을 뚫고 도착한 산악지대는\n차가운 바람이 매섭게 불고 곳곳에 \n얼어붙은 눈과 얼음도 보입니다.","이곳은 새로운 자원이 풍부해 보이지만\n예상 밖의 기후와 몇몇 위협적인 \n동물들도 발견되고 있어요","이 곳에 계속 머물러도 괜찮을까요?"]
]

export var questDepth_4 = [
    ["다행히 연구 결과가 성공적이네요. \n이 에너지를 활용하면 \n씨앗을 빠르게 성장시킬 수 있겠어요.","그런데... 방금 연구 결과를 다시 보니, \n이 에너지는 독성을 품고 있다고 합니다.","씨앗뿐만 아니라 당신에게도 위험할 수 있어요."],
    ["상처가 깊지 않아 다행이에요.\n그리고 현재 가진 자원으로도 충분히 씨앗을 위한 안정적인 환경을 만들 수 있을 것 같아요.","하지만 GIS(지리정보시스템) 분석을 통해\n이 지역이 화산 지대임을 알게 되었어요.\n\n지도에는 표시되지 않은\n화산의 존재가 조금 걱정되네요.","지금 바로 방벽을 쌓는게 어떨까요?"],
    ["앗,,, 묘한 긴장감이 느껴져요..\n오늘도 다른 생존자 무리와\n갈등이 생긴 것 같아요.","이런 갈등이 지속된다면, 작은 불화가 \n큰 대립으로 번질 우려가 있어요.","여기에 계속 머물러도 괜찮을까요?"],
    ["드디어 연구소에 도착을 했습니다.\n연구 자료와 실험 도구들이 충분해서\n계속 연구를 지속할 수 있겠...","(삐삐삐삐) 어? 이 알람은 뭐죠??\n누군가 연구소에 접근하고 있어요!","위험을 감수하고 우선 연구를 빠르게 진행할지, 연구소 보호장치를 먼저 만들고 연구를 느리게 이어갈지 어서 정해야 할 것 같아요!"],
    ["앗! 조심하세요!!반짝이는 꽃에 혹해서 \n손을 뻗었나 보네요. \n하지만 이 꽃은 독초 중 하나에요.","이곳에선 항상 조심해야 해요. \n그래도 별 이상은 없어 보이니 다행이에요.","이런, 물소리가 점점 작아지고 있어요. \n여기서 더 이상 시간을 지체하면 안 될 것 같아요!"],
    ["예상대로 안전한 마을인 것 같습니다.\n GPS로 마을을 탐색해 보니 \n사람의 흔적은 찾아볼 수 없지만, \n외곽에 작은 온실이 남아 있네요.","마을 언덕 쪽을 살펴보니 \n바람이 잘 통하는 넓은 언덕도 있습니다.","어느 곳으로 가는 게 좋을까요?"],
    ["정말 신비한 미네랄을 발견하셨군요!\n이 미네랄은 씨앗에게 큰 도움이 될 것 같아요,\n 이런 미지의 곳에서 정말 훌륭한 수확이네요!","이런, 무슨 일이죠? 함정이 발동된 것 같아요!","미네랄을 제자리에 두면 함정이 멈출지도 몰라요...!"],
    ["다행히 오아시스에 물이 어느 정도 있어\n 씨앗 연구가 차질 없이 진행되고 있습니다.","엇,,! 외부인이 찾아왔습니다. 우리의 물과 그들의 씨앗 영양제를 교환하자고 제안하네요.","남은 물이 그렇게 많진 않은데,,,\n어떻게 해야 할까요?"],
    ["오아시스에 도착해 주변을 탐험하며\n씨앗에게 도움이 될 만한 자원과 땅을 찾던 중\n무언가 땅 속에 있는게 느껴졌습니다","씨앗에게 큰 도움이 될 만한 자원일 수도\n아무것도 아닐 수도 있는 자원이지만\n이걸 확인하기 위해서는 키트가 필요합니다.","당신에게 남은 키트는 단 1개.\n\n오아시스 중심부에서 사용하려던\n 마지막 키트입니다. 어떻게 할까요?"],
    ["미지의 장소에서 신비한 미네랄을 발견한 당신.\n씨앗에게 큰 도움이 될 자원으로 보이네요!","어...엇!! 무슨일이죠? \n미네랄을 챙기려다 함정에 빠질 뻔 했어요!","미네랄을 제자리에 두면 함정이 멈출지도 몰라요...!"],
    ["마을 외곽에 오래된 온실이 있네요!\n 여기에 씨앗을 심어\n푸르른 정원을 만들 수 있어요","하지만, 그 전에....!\n 이 온실을 고치는 건 모험이 될 거예요.","준비되셨나요? \n선택은 여러분의 몫이에요"],
    ["물 소리를 따라 숲속을 걷던 중,\n조용히 물을 마시고 있는 아주 강인하고\n위협적인 야생동물과 마주합니다.","한순간도 눈을 뗄 수 없는 긴장감이 흐릅니다. 움직임 하나로 여러분의 존재를 들킬 수 있습니다.","숨을 죽이고, 당신은 다음 행동을 \n신중하게 고민해야 합니다. \n\n이 순간의 결정이 당신의 운명을 이끌 것입니다"],
    ["연구소 안에는 많은 실험실과\n다양한 실험 도구들로 가득차 있습니다.\n씨앗의 생명을 피우기 위해\n연구에 몰두하고 있던 중","갑자기 강력한 모래폭풍이 \n몰아친다는 경보가 울립니다","많은 자원을 소모하지만 안전을 위해 \n먼저 보호장치를 만들고 연구를 느리게 이어갈지"],
    ["당신은 생존자 그룹과 함께 남아 \n보호를 받으며 그들을 돕고 있습니다.","이 장소는 당신은 안전하지만, \n씨앗에게는 여전히 위협요소가 많습니다.","씨앗에게도 안전한 장소를 탐색하는 데는\n많은 자원이 소비되어 생존자 그룹의\n설득이 필요합니다."],
    ["당신은 안전한 곳으로 생각하고 이동한 곳이 빙하지대임을 깨달았습니다. \n이 환경에서 씨앗을 키우려면 \n두 가지 대안이 있습니다.","첫 번째 방법은 빙하를 녹여 물을 얻고 \n주변 환경 연구를 통해 씨앗을 키우는 것이나,\n긴 여행 동안 씨앗 방치의 위험과\n 많은 자원 소모를 동반합니다.","다른 선택은 고지대에 온실을 건설하여 높은 위치로 이동하는 것이며, 높은 위치 이동의 위험이 있지만 씨앗의 성장을 성공적으로 보장할 수 있습니다."],
    ["당신의 눈앞에는 미처 상상도 못 했던 풍부한 자원들이 펼쳐져 있습니다.","멋지게 씨앗을 키울 마음으로 자원을 \n챙기려는...데..\n..이런!!! \n거대한 늑대가 당신을 향해 달려오고 있어요!","늑대가 자원을 노리고 있습니다.\n주의를 끌어 도망치지 않으면 위험해질 수 있습니다. 빠르게 선택이 필요합니다!"]
]
export var questDepth_5 = [
    ["드디어..! 자원도 충분히 준비되어 \n이제 정말 씨앗을 심을 준비가 되었어요.","다행히 독성이 나타나진 않았지만, GPS 상\n용암 지대라 위험성은 여전히 존재합니다"],
    ["드디어..! 자원도 충분히 준비되어 \n이제 정말 씨앗을 심을 준비가 되었어요.","다행히 독성이 나타나진 않았지만, GPS 상\n용암 지대라 위험성은 여전히 존재합니다"],
    ["드디어..! 자원도 충분히 준비되어 \n이제 정말 씨앗을 심을 준비가 되었어요.","하지만 이 화산 지대의 위험도는 \n여전히 존재합니다.\n어쩌면 당신을 희생해야 할지도 모르죠","그래도 방벽을 설치해두어 안심이긴 한데,,\n화산이 언제 어느 정도의 규모로 터질지 모르니 걱정이긴 하네요."],
    ["드디어..! 자원도 충분히 준비되어 \n이제 정말 씨앗을 심을 준비가 되었어요.","하지만 이 화산 지대의 위험도는\n여전히 존재합니다.\n어쩌면 당신을 희생해야 할지도 모르죠","그래도 방벽을 설치해두어 안심이긴 한데,,\n화산이 언제 어느 정도의 규모로\n터질지 모르니 걱정이긴 하네요."],
    ["생존자들이 모이면서 어엿한 공동체가 완성되었습니다. 그에 따라 자원과 지식이 풍부해져서 \n씨앗 연구에 도움이 되고 있어요.","하지만 여전히 다른 생존자 그룹과\n갈등이 지속되고 있어 경계를 늦추지 말아야 해요.","자, 이제 이 곳에 씨앗을 심어볼까요?"],
    ["새롭게 정착한 이곳은 위험하지 않고 씨앗을 심기에도 좋아 보여요. 이제 정말 씨앗을 심을 수 있겠어요!","새 정착지 중심에 씨앗을 심을까요,\n더 안전한 곳을 찾아 씨앗을 심을까요?","혼자 안전한 곳을 찾아 심는다면 도움 없이 혼자의 힘으로 해내셔야 해요."],
    ["(뚝딱뚝딱..) 어?? 조금 낡아 보이긴 하지만... \n여기 작은 온실이 있어요..!","다만 검증되지 않은 장소라\n씨앗이 잘 자랄 수 있을지는 미지수이지만, \n여태껏 유용한 정보를 많이 준 연구실에 있는 장소니 도움이 될지도 몰라요.","이 온실에 씨앗을 심는 게 좋을까요?"],
    ["맙소사, 급하게 연구하느라 미처 발견하지 못한 지하실이 연구실 구석에 숨겨져 있었네요! 씨앗을 키우기 위한 환경으로도 좋아 보여요!","다만 검증되지 않은 장소라 씨앗이 잘 자랄 수 있을지는 미지수이지만, 여태껏 유용한 정보를 많이 준 연구실에 있는 장소니 도움이 될지도 몰라요.","이 지하실에 씨앗을 심는 게 좋을까요?"],
    ["마침내 숲의 샘물에 도착했습니다.","물이 많진 않지만, 특별한 성분이 들어있는 것 같네요!","과연 이 성분이 씨앗에 도움이 될까요?"],
    ["마침내 숲의 샘물에 도착했어요!\n 헉,, 바로 쓰러질 만큼 너무 고된 여정이었네요...","계속 쉬면 컨디션이 회복되겠지만,,,","지금 씨앗을 심지 않으면 씨앗의 생명이 다할지도 몰라요."],
    ["온실 정비를 마치고 드디어 씨앗을 심을 준비를 마치셨군요.","오는 길에 발견한 특별한 광석에서 추출한 물을 활용하실 건가요? 정말 특별해 보이는 광석이긴 한데..","씨앗이 자라는 데 도움이 될지는 미지수네요."],
    ["언덕에 씨앗을 심기로 결정하셨군요.\n씨앗이 자라기에 좋은 자연환경을 갖춘 언덕인 것 같아요.","한 가지 우려되는 점은 언덕의 토양이 안전한지 확인하지 못했는데,,","지금이라도 제 센서로 토양 검사를 해볼까요?"],
    ["무사히 함정에서 빠져나와 사막의 언덕에 도착했습니다.","최상의 환경은 아니지만, 미네랄을 활용하면 씨앗을 심을 수 있을 것 같아요.","여기에 씨앗을 심고 휴식을 가져볼까요?"],
    ["무사히 유적지를 빠져나와 오아시스에 도착했습니다. 이제 씨앗을 심으려 하는데 갑자기 강한 모래바람이 불어오기 시작하네요.","씨앗을 보호해 줄 수 있는 방풍 장치를 \n설치해야 할까요? 다만 방풍 장치는 \n씨앗만 보호해 줄 수 있어요.","아니면 씨앗이 잘 버텨줄지는 모르겠지만\n다른 안전한 장소를 찾아볼까요?"],
    ["오아시스의 물이 말라가고 있어요.","물이 완전히 마르기 전에 이곳에 씨앗을 심을지, 남은 물을 챙겨서 다른 곳을 찾을지 결정해야 해요."],
    ["헉...! 오아시스의 물이 말라가고 있어요.","머지 않아 큰 비가 내려 오아시스가 다시 살아날 것으로 예측되지만,,,","그때까지 물이 남아 있을지 확신할 수 없기에 여기에 씨앗을 심을지 다른 곳으로 이동해서 심을지 선택하셔야 해요."],
    ["오아시스 중심부가 씨앗을 심기에\n최적의 장소로 알고있던 당신은, \n\n땅을 조사하던 중\n작은 웅덩이를 발견하였고,","이곳에 깊은 지하수가 \n흐르는 것을 알게 되었습니다. ","\n어쩌면 이 웅덩이가 씨앗을 키우기에 \n더 좋은 장소일지도 모르겠어요!"],
    ["오아시스 중심부 조사 결과,\n\n예상했던 대로 이곳은 황량한 사막에서\n씨앗을 심기에 그나마 최적의 장소로 보입니다.","이제 씨앗을 심을까요?"],
    ["함정에서 무사히 빠져나와 오아시스에\n도착해 씨앗을 심을 준비를 하던 중","정체는 모르지만 공포감이 느껴지는 \n거대한 발자국을 발견합니다...!!","당신도,, 살면서 이 정도 크기의 발자국은\n그 어떤 동물이나 생물체의 것으로도\n상상할 수 없을 정도로...\n\n 거..대한 발자국이에요!"],
    ["멋진 책략으로 함정을 뚫고 \n신비한 미네랄까지 챙긴 당신!\n\n제가 함께한 모험가 중\n가장 용기 있는 모험가였어요!","이제 씨앗을 오아시스에 가서 심기만 하면 되는데 ,,, 당신이 매우 지쳐 휴식이 필요해보여요.","아까 챙긴 신비한 미네랄을 사용하면 이 척박한\n사막 언덕에서도 씨앗이 자랄 수는 있는데\n\n이곳에 씨앗을 심고 휴식을 취하는 건 어떨까요?"],
    ["마을을 나온 당신은, \n시원한 바람이 불어 드는 언덕에 도착했습니다.\n왠지 기분 좋은 예감이 드는 이곳","이 장소에 씨앗을 심고 휴식을 취할까요? 아니면 씨앗을 위해 더 좋은 장소를 찾아볼까요?"],
    ["온실 수리를 마치고 드디어,\n씨앗을 심는 이 순간이 왔네요!\n당신이 수리한 이 온실은\n씨앗을 키우기에 너무 훌륭해 보여요!","마지막으로 아까 발견한 특별한 광석에서\n추출한 물을 활용할지 결정할 순간이에요","정말 특별해 보이는 광석이긴 한데.. 결과도 특별할지는...."],
    ["숲을 계속 탐험하던 중 샘물에 도착했습니다.\n\n이제 당신도 지쳐가고 ,, 씨앗의 생명력도\n위태롭습니다. 이제 씨앗을 심지 않으면\n영영 꽃을 피우지 못 할지도 몰라요..","어..어...! 당신도 이러다 정말 쓰러질것 같아요.. 당신에게도 휴식이 필요해보여요","당신도, 씨앗도 위험한 지금 우선 휴식을 취할지 씨앗을 먼저 심을지 선택해야만 해요"],
    ["야생동물이 떠난 호숫가를 여유 있는 마음으로 둘러보니 씨앗을 심기에 좋은 기후와 풍족한 자원이 있는 이곳","하지만 언제 또 위협적인 동물이 나타날지 몰라 이곳에 씨앗을 심는게 좋은 생각인지 확신 할 수는 없어요"],
    ["보호장치를 만들던 중, 연구소 내 구석에 오래된 작은 소형 온실을 발견했습니다.","그곳에는 기존 사람들이 남겨둔 유용한 정보들과 몇몇 특별한 자원이 보이지만 낡고 오래되어….","씨앗을 심었을 때 결과는 예상할 수 없습니다. 어디에 씨앗을 심는 게 좋을까요?"],
    ["빠르게 연구를 이어가던 중 구석에서\n지하실 통로를 발견했습니다. \n\n지하실에는 특별한 환경이 갖춰져 있는 듯\n보이지만 씨앗을 심었을 때 결과는 미지수입니다.","쿵……. 쾅…. 쿵.. 모래폭풍이 \n벌써 여기까지 왔나봐요!\n연구실이 무너지고 있어요!!…!","새로 발견한 지하실에 씨앗을 심어\n특별한 꽃을 피우는 모험을 감수할지,\n\n아니면 안전하게 기존 장소에 심을지,\n빠르게 선택해야 합니다."],
    ["새롭게 찾은 장소는 씨앗을 심기에 최적의 장소로 보이기는 하지만 안정성 여부는 불확실한 곳으로\n안정성 체크가 필요해보입니다. ","이미 장소를 찾는 동안 씨앗의 생명력이 많이 소멸되었네요.. 안정성을 체크하는 동안 씨앗이 버텨줄 수 있을지 모르겠어요."],
    ["다행히 경계는 잘 유지되어 아직은 안전합니다. 자원 또한 씨앗을 심기에 충분합니다. 하지만 위협으로부터 자유롭지는 못한 이곳,","자 이제 씨앗을 심어야만 하는 순간이 다가왔습니다.","혼자만 아는 장소에 씨앗을 심거나, 그룹과 함께 씨앗을 지킬 수 있는 장소에 심을 수도 있습니다."],
    ["씨앗과 당신은 다행히 빙하지대의 안전한 지하로 내려왔습니다. 환경은 키우기에 적합해 보이지만 안전성에 대한 확신은 없습니다..","아직은 씨앗의 상태가 괜찮지만 앞으로 얼마나 버텨줄지 모르는 지금, 안전을 위해 또 다른 장소를 찾는 게 최선일지는…."],
    ["당신은 고지대 온실을 만들기 위해 높은 곳으로 이동하고 있습니다. 높이가 높아질수록 당신과 씨앗이 점점 더 위험에 노출되고 있는 게 느껴집니다.","더 높은 곳의 온실에서 씨앗을 심으면 씨앗의 성장도 빠르고 더 멋진 결과가 나오겠지만 이대로 더 올라가다가는 씨앗이 버텨줄지 모르겠어요."],
    ["풍부한 자원은 잃었지만 안전하게 도망쳐\n작은 동굴로 들어왔습니다. \n\n다행히 이곳은 평온하고 안전해 보입니다. ","물은 고요히 흐르고 있어 씨앗을 심기에 충분해 보입니다. 현재 이 장소에서는 아직 위협 요소가 발견되지 않은 것 같습니다.","이대로 이곳에 씨앗을 심는 건 어떨까요?"],
    ["휴! 다행히 호신 무기로 늑대를 물리쳤네요!\n풍부한 자원까지 얻었으니,\n이제 씨앗을 심기 위한 온실을 만들기만 하면 돼요.","굳이 산의 정상까지 가지 않아도 당신이 늑대를 물리친 덕분에 충분한 자원을 얻었으니 \n이 장소에 온실을 만들고 씨앗을 심는 건 어때요?"]    
]


export var answerDepth_0 = [
    "왼쪽 길로 간다","오른쪽 길로 간다"
]

export var answerDepth_1 = [
    ["위험지역으로 진입한다.","안전을 선택하여 다른 경로로 이동한다,\n 씨앗이 위험에 노출되는 것을 피한다."],
    ["동굴로 우선 피신한다.","모래바람을 뚫고 자원을 찾으러간다"]
]

export var answerDepth_2 = [
    ["호신 무기를 사용해 약탈자와 전투를 벌인다.","약탈자와 협상을 시도한다."],
    ["근처 동굴로 이동하여 야생동물이 싫어하는 향을 피워 다가오지 못하도록 한다.","야생동물을 길들이는 시도를 한다."],
    ["사막 유목민과 협상하여 \n새로운 자원을 얻는다.","협상하지 않고 광석을 챙겨 \n조심스럽게 탈출한다."],
    ["신호를 따라간다","신호를 따라가지 않는다"]
]

export var answerDepth_3 = [
    ["새로운 가능성을 발견할 수 있으므로 연구를 한다.","전투의 상처를 치료하는 게 먼저다.\n 안전한 곳으로 이동한다."],
    ["생존자들을 찾아간다.","연구소로 향한다."],
    ["물소리를 따라 숲으로 들어간다.\n특별한 것을 발견할지도 모른다.","이제 씨앗을 위해 안정적인 \n환경이 필요하니 마을로 간다"],
    ["미지의 유적지로 간다. 무언가 발견할지도 모른다.","오아시스로 간다. 충분히 쉬며 씨앗 연구를 할 수 있다."],
    ["오아시스로 간다. \n그곳은 필요로 하는 것들을 갖춘 곳이다.","미지의 장소로 간다. \n무언가 발견할 지도 모른다."],
    ["근처 마을로 간다.\n씨앗에게도 안정적인 환경일 것이다.","물 소리를 따라 숲으로 들어간다.\n풍부한 자원을 발견할지도 모른다."],
    ["연구소로 향한다. 씨앗을 심고 \n생명을 피우기 위한 단서가 될 것이다.","생존자들과 함께한다. \n그들은 나를 보호해줄 수 있다."],
    ["여차하면 위험하니 이곳을 떠나 \n안전한 곳으로 이동한다.","새로운 가능성을 발견할 수 있으므로 \n이곳에 머무른다."],
]
export var answerDepth_4 = [
    ["연구 결과에 따라 씨앗을 심을 준비를 한다.","독성을 피하기 위해 연구 결과를 따르지 않는다."],
    ["자원을 전부 활용하여 안전하게 방벽을 쌓는다.","일부 자원을 비상용으로 남기고 방벽 대신\n간단한 보호 설비를 만든다."],
    ["현재 장소에서 생활한다. 다른 생존자 무리와는 평화적으로 해결할 수 있을 것이다.","그룹과 함께 다른 지역으로 이동하여 정착한다."],
    ["우선 빠르게 연구를 계속한다.","침입자 보호 장치를 만들고 나서 연구를 지속한다."],
    ["큰 문제는 없어 보이니 계속 나아간다.","약물과 치료제를 찾아 독성 물질을 제거한다."],
    ["유용한 도구와 자원이 있는 온실에서 \n씨앗을 심을 준비를 한다.","자연적 환경이 좋은 언덕에서 씨앗을 심을 준비를 한다."],
    ["미네랄을 들고 함정을 뚫고 나간다.","미네랄을 내려놓고 안전히 돌아간다."],
    ["물을 주고 영양제를 받는다.","물을 주지 않고 영양제를 받지 않는다."],
    ["키트를 사용하여 땅을 조사한다.","오아시스의 중심부로 향한다."],
    ["미네랄을 내려놓고 안전히 돌아간다.","미네랄을 들고 함정을 뚫는다."],
    ["마을 벗어나 다른 장소를 더 둘러본다.","온실을 수리한다."],
    ["야생 동물을 피해 숲을 계속 탐험한다.","야생 동물이 물을 다 마실때까지 숨어 있는다."],
    ["먼저 보호장치를 만든다.","연구를 빠르게 진행한다."],
    ["생존자 그룹을 설득하여\n씨앗에게 안전한 지역을 탐색한다.","자원을 아끼고 현재장소의\n경계를 강화한다."],
    ["빙하를 녹여 지하로 이동한다","고지대에 온실을 건설한다"],
    ["자원을 포기하고 도망간다.","호신도구로 늑대와 맞서 자원을 지킨다."],
]
export var answerDepth_5 = [
    ["자원이 충분해 씨앗을 키우기 적합하니\n 일단 씨앗을 심는다.","우선 내가 안전해야 씨앗을 키울 수 있으니\n비교적 안전한 곳에서 씨앗을 심는다."],
    ["자원이 충분해 씨앗을 키우기 적합하니\n 일단 씨앗을 심는다.","우선 내가 안전해야 씨앗을 키울 수 있으니\n비교적 안전한 곳에서 씨앗을 심는다."],
    ["자원이 충분해 씨앗을 키우기 적합하니\n 일단 씨앗을 심는다.","잠시 방벽을 나가 화산의 상태를 더 조사하고 \n안정성 확보 후 씨앗을 심는다."],
    ["자원이 충분해 씨앗을 키우기 적합하니\n 일단 씨앗을 심는다.","잠시 방벽을 나가 화산의 상태를 더 조사하고 \n안정성 확보 후 씨앗을 심는다."],
    ["자원이 충분해 씨앗을 키우기 적합하니\n 일단 씨앗을 심는다.","위협에도 안전한 비밀 외곽 지역에 씨앗을 심는다."],
    ["새 정착지 중앙에 씨앗을 심는다.","홀로 안전한 곳을 찾아 씨앗을 심는다."],
    ["기존의 온실에 씨앗을 심는다.","발견한 오래된 소형 온실에 심는다."],
    ["지하실에 씨앗을 심는다.","지하실 말고 기존 예정된 장소에 씨앗을 심는다."],
    ["씨앗을 심고 물을 준다.","주변에서 더 깨끗한 물을 찾아본다."],
    ["일어나 씨앗을 심는다.","나의 회복이 우선이니 좀 더 쉰 후 씨앗을 확인한다."],
    ["씨앗을 심고 특별한 광석을 사용한다.","씨앗을 심고 특별한 광석을 사용하지 않는다."],
    ["어떤 결과가 나올지 모르지만 일단 씨앗을 심는다.","토양과 물의 품질을 먼저 검사한다."],
    ["이곳에 씨앗을 심는다.","조금 더 좋은 곳을 찾아본다."],
    ["방풍 장치를 설치하고 이곳에 씨앗을 심는다.","다른 안전한 곳을 찾아 씨앗을 심는다."],
    ["영양제와 남은 물을 활용해 이곳에 심는다.","남은 물을 챙겨 다른 곳을 찾는다."],
    ["이곳에 씨앗을 심는다.","남은 물을 챙겨 다른 곳을 찾는다."],
    ["원래 계획대로 중심부에 가서 심는다.","새로 발견한 웅덩이에 심는다."],
    ["더 좋은 곳이 있을지도 모르니 더 탐색한다.","조사를 충분히 했으니 이곳에 씨앗을 심는다."],
    ["오아시스를 빠져 나와 \n다른 곳을 찾아 씨앗을 심는다.","이곳은 충분히 안전하다. 여기에 씨앗을 심는다."],
    ["씨앗에게 조금 더 좋은 환경을 찾는다.","불완전하게나마 씨앗을 키운다."],
    ["주변을 더 둘러본 후 결정한다.","이곳에서 씨앗을 심는다."],
    ["특별한 물을 사용하여 씨앗을 심는다.","온실 자원으로만 씨앗을 심는다."],
    ["나의 회복을 위해 먼저 휴식한다.","내 임무를 다하기 위해 씨앗을 우선 심는다."],
    ["다른 안전한 곳을 찾아 씨앗을 심는다.","이곳에 씨앗을 심는다"],
    ["기존 온실에 씨앗을 심는다.","발견한 오래된 소형 온실에 심는다."],
    ["지하실에 씨앗을 심는다.","기존 장소에 씨앗을 심는다."],
    ["안정성 체크 후 심는다.","지체없이 당장 심는다."],
    ["나만 아는 장소에 심고 나만 돌본다.","거주지 중앙에 심고 그룹과 씨앗을 지킨다."],
    ["조금 더 적합한 곳을 찾아본다.","이곳에 씨앗을 심는다."],
    ["더 높은 곳에서 온실 건설 후 심는다.","현재 위치에 온실 건설 후 심는다."],
    ["동굴에서 자원만 챙겨 장소를 더 물색한다.","이곳늠 충분하다. 이곳에 씨앗을 심는다."],
    ["산의 정상에서 자원 추가 획득 후 심는다","상대적으로 안전한 이곳에서 심는다."],
]