import gsap from 'gsap';
import FlowerCard from './FlowerCard';

export default class FlowerCardLoading extends FlowerCard {
    constructor(view, idx, eventDispatcher) {
        super(view, idx, eventDispatcher)
        this.view = view;
        this.idx = idx;
        this.eventDispatcher = eventDispatcher;
        this.answerText = this.view.querySelectorAll('.button-answer');
        this.visibleAnswerText = this.answerText
        this.background = this.view.querySelector('.asset');

        this.callback = null;
    }

    //////////////////////////////////////////////////////////////
    /////////////////////////// Card State ///////////////////////
    //////////////////////////////////////////////////////////////
    cardOn(delay, callback) {
        gsap.set(this.view, {
            display: 'block',
            pointerEvents: 'auto',
            onComplete: () => {
                this.appearBackground(delay, callback)
            }
        })
    }

    cardOff(val) {
        gsap.killTweensOf(this.view, 'display')
        gsap.set(this.view, { pointerEvents: 'none' })

        if (val == 0) {
            this.resetCard()
        } else {
            this.disappearAnswerText(this.callback)
        }
    }

    playVideoCheck(e) {
        const { duration, currentTime } = e.target;
        if (currentTime / duration >= 0.8) {
            this.cardOff(1)
            this.background.removeEventListener('timeupdate', this.playVideoCheckEvent)
        }
    }
    
    appearBackground(delay, callback) {

        this.playVideoCheckEvent = this.playVideoCheck.bind(this)

        gsap.set(this.background, {
            autoAlpha: 1,
            onComplete: () => {
                this.callback = callback;
                if (this.background.tagName == 'VIDEO') {
                    if(!document.body.classList.contains('hide-video')) {
                        if (this.background.paused) {
                            this.background.currentTime = 0.01;
                            this.background.play()
                            this.background.addEventListener('timeupdate', this.playVideoCheckEvent)
                        }
                    } else {
                        gsap.delayedCall(3, () => this.cardOff(1))   
                    }
                }

                gsap.delayedCall(delay - 0.3, () => {
                    if (callback) {
                        this.appearAnswerText(callback)
                    } else {
                        this.appearAnswerText()
                    }
                    
                })
            }
        })
    }
    appearAnswerText(callback) {
        gsap.set(this.visibleAnswerText, { display: 'block' })
        this.sx = 0.3;

        this.visibleAnswerText.forEach((answer, idx) => {
            const _stagger = idx * 0.5;
            const _text_delay = 0.6;
            // Background : scaleX
            
            gsap.set(answer.querySelector('.button-background'), { scaleX: this.sx })
            
            gsap.to(answer.querySelector('.button-background'), { 
                scaleX: 1, 
                duration: 0.9,
                ease: 'power2.out',
                delay: _stagger 
            })
            gsap.to(answer.querySelector('.button-background'), { 
                opacity: 1, 
                duration: 0.8,
                ease: 'power1.out',
                delay: _stagger 
            })
            // Background : text
            gsap.to(answer.querySelector('.answer-text'), { 
                opacity: 1,
                duration: 0.7,
                ease: 'power1.out',
                delay: _stagger + _text_delay,
                onComplete: () => {
                    if (idx == this.visibleAnswerText.length - 1) {
                        this.eventDispatcher.dispatch('_enableClick', '')
                        if (callback) {
                            this.cardOff(1)
                        }
                    }
                }
            })
        })
    }
    // dot animation
    /** Quest Text 등장 */
    resetCard(){
        gsap.set(this.view, { display: 'none' })
        this.callback = null;
        // gsap.killTweensOf(this.questText)
        gsap.killTweensOf(this.answerText)

        this.killChildTweensOf(this.answerText)
        gsap.killTweensOf(this.background)

        gsap.killTweensOf(gsap.utils.toArray('.button-answer', this.view), 'all')
        gsap.killTweensOf(gsap.utils.toArray('.button-background', this.view), 'all')
        gsap.killTweensOf(gsap.utils.toArray('.answer-text', this.view), 'all')

        gsap.set(gsap.utils.toArray('.button-answer', this.view), { clearProps: 'all', display: 'none' })
        gsap.set(gsap.utils.toArray('.button-background', this.view), { clearProps: 'all', scaleX: this.sx, opacity: 0 })
        gsap.set(gsap.utils.toArray('.answer-text', this.view), { clearProps: 'all', opacity: 0 })


        gsap.set(this.background, { clearProps: 'all', autoAlpha: 0 })
        if (this.background && this.background.tagName == 'VIDEO') {
            if(!this.background.paused) {
                this.background.pause()
            }
            this.background.currentTime = 0;
        }
    }

}